import { AlertDescription, Box, Image, useMediaQuery } from '@chakra-ui/react'
import { Outlet, useLocation } from 'react-router-dom'
import { ROUTES, secondaryRoutes } from '../routes'

import Authenticate from '../modules/Auth/Authenticate'
import CookiesConsent from './CookiesConsent'
//import Footer from './Footer'
import Footer from '../modules/Quest/components/Footer'
import OnboardingModal from '@olagg/ui-kit/Onboarding/OnboardingModal'
import SecondaryNavbar from './Navbar/SecondaryNavbar'
import { useAuthStore } from '@olagg/store'
import { useEffect } from 'react'
import usePartners from '../modules/Quest/hooks/usePartners'

const Layout = () => {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')
  const { pathname } = useLocation()
  const { theme } = usePartners()

  const { loggedIn } = useAuthStore()

  const secondaryR = secondaryRoutes.find(route => (pathname.includes(route) && !pathname.includes('/novedades')))

  const isLanding = pathname.includes(ROUTES.LANDING)
  const isNews = pathname.includes(ROUTES.NEWS)
  const isGame = pathname.includes(ROUTES.GAME)
  const isLandingSecure = pathname.includes(ROUTES.LANDING_SECURE)
  const isHome = pathname === ROUTES.HOME
  const isProfile = pathname === ROUTES.MY_PROFILE
    || pathname === ROUTES.MY_PROFILE_DATA
    || pathname === ROUTES.MY_PROFILE_PICTURE
    || pathname === ROUTES.SCHOLARSHIPS
    || pathname === ROUTES.REWARDS
    || pathname.includes(ROUTES.SCHOLARSHIPS)
    || pathname === ROUTES.REFERRALS
  const isToken = pathname === ROUTES.TOKENS
  const isPublicProfile = pathname.includes(ROUTES.PUBLIC_PROFILE)
  const isSecurity = pathname.includes(ROUTES.SECURITY)
  const maxWidth = import.meta.env.VITE_LAYOUT_MAXWIDTH;
  // const maxWidth = isHome ? 'none' : import.meta.env.VITE_LAYOUT_MAXWIDTH;

  const bgGradient = isLargerThan768 ? 'linear-gradient(45deg, #912FD4 54%, #C1698A 110%)' : 'linear-gradient(45deg, #C1698A 87%, #912FD4 97%)'
  const mainBg = 'linear-gradient(0deg, #0c0324 0%, #240340 100%)'
  const background = (() => {
    switch (true) {
      // case isHome:
      //   return 'linear-gradient(186.23deg, #CA747A -1.27%, #912FD4 43.1%, #4C288C 90.16%);'
      case isPublicProfile:
        return bgGradient //'linear-gradient(186.23deg, #CA747A -1.27%, #912FD4 43.1%, #4C288C 90.16%);'
      case !secondaryR && !pathname.includes('mi-perfil'):
        return mainBg //'olaggDark.100'
      case !secondaryR && pathname.includes('mi-perfil'):
        return '#FFFFF'
      default:
        return bgGradient //'linear-gradient(186.23deg, #CA747A -1.27%, #912FD4 43.1%, #4C288C 90.16%);'
    }
  })()

  const centerNav = {
    maxWidth: import.meta.env.VITE_LAYOUT_MAXWIDTH,
    margin: '0 auto'
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = 'auto'
    document.body.style.marginLeft = '0'
  }, [pathname]);

  return (
    <>
      <Authenticate />
      <Box
        background={theme.background}
        minHeight="100vh"
        position="relative"
        w='100vw'
        maxW='100%'
        overflowX='clip'
        top='0'
      >
        <Image
          opacity="28%"
          position="absolute"
          src="https://olagg-p.s3.amazonaws.com/bg-hero-home-pink-top.png"
          maxH='600px'
          w="calc(100vw)"
          zIndex={-1}
        />
        {(secondaryR || isPublicProfile) && (
          <Image
            position="absolute"
            right={isLargerThan768 ? '-100' : '0'}
            src={
              isLargerThan768
                ? '/texture.png'
                : '/mobile-texture.png'
            }
          />
        )}
        {/* {
          isProfile && isLargerThan768 && (
            <Box
              h="85vh"
              position="absolute"
              top="25vh"
              backgroundColor="white"
              width="100vw"
              left="0"
            />
          )
        } */}
        {
          // isToken && (
          //   <Box
          //     h="125vh"
          //     position="absolute"
          //     top="25vh"
          //     backgroundColor="white"
          //     width="100vw"
          //     left="0"
          //   />
          // )
        }

        <CookiesConsent />
        {/*<NavbarV2 />*/}
        {secondaryR && <SecondaryNavbar />}
        <Box
          // maxWidth={maxWidth}
          margin="0 auto"
          p='0'
          // padding={isProfile || isToken || isSecurity ? { base: '50px 15px 0 15px', md: '50px 30px 0 30px' } : { base: '0 10px', md: '0 30px' }}
          id='layout'
        >
          <Outlet />
        </Box>
        {!isLanding && (
          loggedIn && (
            <Footer />
          )
        )}

        {isLandingSecure && !loggedIn && (
          <Footer />
        )}

      </Box>
    </>
  )
}

export default Layout
