import { Box } from '@chakra-ui/react';
import Wheel from './Wheel';
import CenterLogo from './CenterLogo';
import WinnerBar from './WinnerBar';
import useRoulette from './useRoulette';

const Roulette = ({
  textColor = 'white',
} : {
  textColor?: string,
}) => {
  const { values, animation } = useRoulette();
  
  if (!values) return null;
  
  return (
    <Box position='relative' w='380px' h='380px'>
      <Box position='absolute' left='0' top='0' animation={animation} >
        <Wheel values={values} textColor={textColor} />
      </Box>
      <Box position='absolute' top='165' left='165'>
        <CenterLogo />
      </Box>
      <Box position='absolute' top='9' left='182'>
        <WinnerBar />
      </Box>
    </Box>
  )
}

export default Roulette;
