import { Box, Flex, Text } from '@chakra-ui/react';
import { useUsers } from '@olagg/api-hooks';
import { Charco } from './Charco';
import { Laguna } from './Laguna';
import { Mar } from './Mar';
import { Ola } from './Ola';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';

export default function TokenUnlockProgressBar() {
  const { t } = useTranslation();
  const { count: currentUsers } = useUsers({ page: 1, limit: 1 });
  const refCharco = useRef<HTMLDivElement | null>(null);
  const refOla = useRef<HTMLDivElement | null>(null);
  const initialUsers = import.meta.env.VITE_INITIAL_USERS_COUNT ?? 0;
  const targetUsers = import.meta.env.VITE_TARGET_USERS_COUNT ?? 10000;

  const progress = Math.ceil(100 * ((currentUsers ?? 0) - initialUsers) / targetUsers);

  const gradient = `linear-gradient(90deg,
    rgba(163,23,227,1) 0%,
    rgba(109,19,245,1) ${progress}%,
    rgba(226,232,240,1) ${progress + 10}%,
    rgba(226,232,240,1) 100%
  )`;
  
  return (
    <Flex alignItems='center' w='full' justifyContent='center'>
      <Text color='gray.900' fontSize='1rem' fontWeight='600' textAlign='center' ml='5' display={{ base: 'none', md: 'flex'}}>
        {t('TokenProgressBar.title')}
      </Text>
      <Flex h='72px' minW='320px' maxW='650px' w='full' mx='10' alignItems='center' justifyContent='space-between' bgColor='white' position='relative'>
        <Box 
          position='absolute' bgColor='gray.200' h='6px' zIndex='1'
          top='27px'
          left={`${(refCharco.current?.offsetWidth ?? 0) / 2}px`}
          right={`${(refOla.current?.offsetWidth ?? 0) / 2}px`}
          background={gradient}
        ></Box>
        <Charco ref={refCharco} on={progress > 0} />
        <Laguna on={progress > 23} />
        <Mar on={progress > 56} />
        <Ola ref={refOla} on={progress > 90} />
      </Flex>
    </Flex>
  )
}
