export default function WinnerBar() {
  return (
    <svg width="16" height="41" viewBox="0 0 16 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.4509 1.14451V35.3931L8.22543 40.1618L1 35.3931V1.14451C3.31214 1.14451 5.76879 1 8.22543 1C10.6821 1 12.9942 1 15.4509 1.14451Z" fill="url(#paint0_linear_6391_24068)"/>
      <path d="M15.4509 1.14451V35.3931L8.22543 40.1618L1 35.3931V1.14451C3.31214 1.14451 5.76879 1 8.22543 1C10.6821 1 12.9942 1 15.4509 1.14451Z" stroke="url(#paint1_linear_6391_24068)" strokeMiterlimit="10"/>
      <defs>
        <linearGradient id="paint0_linear_6391_24068" x1="8.22543" y1="39.7283" x2="8.22543" y2="3.60116" gradientUnits="userSpaceOnUse">
          <stop offset="0.1" stopColor="#FB3DC6"/>
          <stop offset="0.2" stopColor="#EA39C3"/>
          <stop offset="0.3" stopColor="#BE2EBE"/>
          <stop offset="0.5" stopColor="#791EB5"/>
          <stop offset="0.7" stopColor="#4712AA"/>
          <stop offset="0.8" stopColor="#3F0F99"/>
          <stop offset="0.9" stopColor="#300A7E"/>
          <stop offset="1" stopColor="#110144"/>
        </linearGradient>
        <linearGradient id="paint1_linear_6391_24068" x1="0.277457" y1="20.6532" x2="16.1734" y2="20.6532" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BD94FF"/>
          <stop offset="0.2" stopColor="#B380F1"/>
          <stop offset="0.5" stopColor="#9C4FCD"/>
          <stop offset="1" stopColor="#760094"/>
        </linearGradient>
      </defs>
    </svg>
  )
}
