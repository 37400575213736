export default function CenterLogo() {
  return (
    <svg width="50" height="52" viewBox="0 0 50 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path style={{ mixBlendMode: 'multiply' }} opacity="0.6" d="M22.8324 51.0571C35.4423 51.0571 45.6647 40.8347 45.6647 28.2247C45.6647 15.6147 35.4423 5.39233 22.8324 5.39233C10.2224 5.39233 0 15.6147 0 28.2247C0 40.8347 10.2224 51.0571 22.8324 51.0571Z" fill="url(#paint0_radial_6391_24058)"/>
      <path d="M36.9344 36.4794C43.6501 29.7637 43.6501 18.8755 36.9344 12.1598C30.2188 5.44416 19.3305 5.44414 12.6149 12.1598C5.89919 18.8755 5.89921 29.7637 12.6149 36.4794C19.3305 43.195 30.2188 43.195 36.9344 36.4794Z" fill="url(#paint1_linear_6391_24058)"/>
      <path d="M36.5713 34.2482C42.0833 27.7256 41.2641 17.9697 34.7415 12.4577C28.219 6.94573 18.463 7.76496 12.951 14.2875C7.43905 20.8101 8.25828 30.566 14.7808 36.078C21.3034 41.59 31.0593 40.7708 36.5713 34.2482Z" fill="url(#paint2_linear_6391_24058)"/>
      <path d="M34.6825 18.3976H30.7808L24.8559 14.9294L18.931 18.3976H15.0293L16.3299 20.7098L16.9079 21.7213V28.6578L22.8328 32.126L23.4108 33.1375L24.7114 35.4497L26.012 33.1375L26.59 32.126L32.5149 28.6578V21.7213L33.0929 20.7098L34.3935 18.3976H34.6825ZM24.8559 33.4266L16.7634 19.4092H32.8039L24.7114 33.4266H24.8559ZM24.8559 16.23L28.9021 18.3976H20.8096L24.8559 16.23ZM31.7923 28.0797L27.7461 30.2473L31.7923 23.311V27.9352V28.0797ZM17.9195 23.4554L21.9657 30.3919L17.9195 28.2242V23.6V23.4554Z" fill="white"/>
      <path d="M26.734 20.8547L24.2773 25.19L24.9999 26.3461L26.1559 24.323H27.1675V24.612C27.023 24.612 26.3005 25.9125 26.3005 25.9125H27.601L30.3467 21.1438H26.5895L26.734 20.8547ZM27.8901 23.1669H26.8785V22.8779C27.023 22.8779 27.601 22.0108 27.601 22.0108H28.6126V22.2998C28.4681 22.2998 27.8901 23.1669 27.8901 23.1669Z" fill="white"/>
      <path d="M23.2654 25.623L24.7105 27.9352L24.855 28.3687L24.9995 27.9352L25.8666 26.6346H27.1672L24.855 30.5364L21.9648 25.623H23.2654Z" fill="white"/>
      <path d="M19.2207 20.8547L21.3883 24.612H23.2669L25.4346 20.8547H19.2207ZM22.6889 23.4559H22.2554L21.5328 22.1553V21.8663C21.3883 21.8663 21.6773 21.8663 21.6773 21.8663H23.7005V22.1553C23.5559 22.1553 22.8334 23.3114 22.8334 23.3114L22.6889 23.4559Z" fill="white"/>
      <defs>
        <radialGradient id="paint0_radial_6391_24058" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(22.8324 28.2247) scale(22.8324 22.8324)">
          <stop offset="0.6" stopColor="#662E29"/>
          <stop offset="0.7" stopColor="#75423E"/>
          <stop offset="0.8" stopColor="#AF9290"/>
          <stop offset="0.9" stopColor="#DACDCB"/>
          <stop offset="1" stopColor="white"/>
        </radialGradient>
        <linearGradient id="paint1_linear_6391_24058" x1="13.6952" y1="13.2359" x2="39.7068" y2="39.392" gradientUnits="userSpaceOnUse">
          <stop stopColor="#DC1DF8"/>
          <stop offset="0.2" stopColor="#B33FFA"/>
          <stop offset="0.3" stopColor="#7178FF"/>
          <stop offset="0.4" stopColor="#6C6BFD"/>
          <stop offset="0.5" stopColor="#6044F8"/>
          <stop offset="0.6" stopColor="#5621EE"/>
          <stop offset="0.7" stopColor="#5920DE"/>
          <stop offset="0.8" stopColor="#5E1EC7"/>
          <stop offset="1" stopColor="#F5BFFF"/>
        </linearGradient>
        <linearGradient id="paint2_linear_6391_24058" x1="37.6219" y1="11.6306" x2="6.40808" y2="42.7" gradientUnits="userSpaceOnUse">
            <stop offset="0.3" stopColor="#303030"/>
            <stop offset="1" stopColor="#000604"/>
        </linearGradient>
      </defs>
    </svg>
  )
}
