import { Center, Spinner } from '@chakra-ui/react'

const Loading = (props: any) => {
  return (
    <Center h="100px" w='full' {...props}>
      <Spinner color="white" size="xl" />
    </Center>
  )
}

export default Loading
