import { Flex } from '@chakra-ui/react';
import TokenUnlockProgressBar from '@olagg/ui-kit/TokenUnlockProgressBar';

export default function TokenProgessBar() {
  return (
    <Flex w='full' bgColor='white' justifyContent='center'>
      <TokenUnlockProgressBar />
    </Flex>
  )
}
