import { Box, Button, Flex, Text } from '@chakra-ui/react';

import Modal from "../../../components/Modal/Modal";
import { Quest } from "@olagg/db-types"
import Xp from "@olagg/ui-kit/svg/Xp";
import XpStatic from "@olagg/ui-kit/svg/XpStatic";
import { useAuthStore } from "@olagg/store"
import { useTranslation } from "react-i18next";

export default function ClaimedModal({
  handleToggleModal ,
  isOpen,
  quest,
  reload
}: {
  handleToggleModal: (s) => void,
  isOpen: boolean,
  quest: Quest,
  reload: () => void,
}) {
  const { t, i18n } = useTranslation();
  const { me } = useAuthStore();

  const handleClose = async () => {
    await reload()
    handleToggleModal(false)
  }

  return (
    <Modal onClose={handleClose} isOpen={isOpen}>
      <Box p='5'>
        <Box py='2' borderBottom='1px solid #BEB079'>
          <Text textAlign='center' fontSize='1.5rem' fontWeight='600'>{
            i18n.language === 'es' ? quest?.title : (i18n.language === 'en' ? (quest.extraData.en?.title && quest.extraData.en?.title) || quest?.title : (quest.extraData.pt?.title && quest.extraData.pt?.title) || quest?.title)
          }</Text>
        </Box>
        <Box my='5' py='3' borderRadius='10' bgColor='gray.100'>
          <Flex justifyContent='center' alignItems='center' gap='1'>
            <Text fontSize='3.625rem' fontWeight='600'>+{quest.score}</Text>
            <XpStatic width='65' height='65' color='black' />
          </Flex>
          <Text textAlign='center' fontSize='1rem'>{t('quests.congratulations')}</Text>
        </Box>
        <Button w='full' variant='questsDefault' onClick={handleClose}>{t('quests.back_to_quests')}</Button>
        <Text mt='5' textAlign='center' fontSize='1rem' color='gray.600'>{t('quests.xp_accumulated')}</Text>
        <Flex justifyContent='center' alignItems='center' gap='1'>
          <Text fontSize='1.25rem' fontWeight='500'>{me?.level?.score}</Text>
          <XpStatic width='25' color='#4A5568' />
        </Flex>
      </Box>
    </Modal>
  )
}
