import { useEffect } from 'react';
import { Spinner } from '@chakra-ui/react'
import { ConnectButtons } from './ConnectButtons';
import { useConnectWallet } from './hooks/useConnectWallet';
import { useActiveAccount, useActiveWalletChain, useActiveWalletConnectionStatus } from 'thirdweb/react';

const ConnectWallet = () => {
  const { syncWallet, disconnectWallet, isLoading, setIsLoading } = useConnectWallet();
  const status = useActiveWalletConnectionStatus()  
  const activeChain = useActiveWalletChain();
  const activeAccount = useActiveAccount();

  useEffect(() => {
    if (status == "connected" && activeChain) {
      syncWallet();
    }
  }, [status, activeAccount, activeChain]);

  if (status === 'connecting' || isLoading) return <Spinner color='#5210FF' />

  return <ConnectButtons setIsLoading={setIsLoading} disconnectWallet={disconnectWallet} />
}

export default ConnectWallet;
