import { Flex, Grid, GridItem, Image, Text } from "@chakra-ui/react"

import { Link } from "react-router-dom"
import { NavbarLogoOla } from "@olagg/ui-kit"
import { ROUTES } from "../../../../routes"
import discordImg from '../../../../../public/images/home/social/discord.png'
import facebookImg from '../../../../../public/images/home/social/facebook.png'
import instagramImg from '../../../../../public/images/home/social/instagram.png'
import tiktokImg from '../../../../../public/images/home/social/tiktok.png'
import twitchImg from '../../../../../public/images/home/social/twitch.png'
import twitterImg from '../../../../../public/images/home/social/twitter.png'
import usePartners from "../../hooks/usePartners"
import { useTranslation } from "react-i18next"
import youtubeImg from '../../../../../public/images/home/social/youtube.png'

const Footer = () => {
  const {theme} = usePartners()
  const { t } = useTranslation();

  const data = [
    {src: discordImg, link: 'https://discord.gg/olagg'},
    {src: twitterImg, link: 'https://twitter.com/OLAGuildGames?s=18'},
    {src: tiktokImg, link: 'https://www.tiktok.com/@olaguildgames?is_from_webapp=1&sender_device=pc'},
    {src: youtubeImg, link: 'https://www.youtube.com/@OlaGuildGames'},
    {src: twitchImg, link: 'https://www.twitch.tv/olaguildgames'},
    {src: instagramImg, link: 'https://instagram.com/olaguildgames?utm_source=web&utm_medium=header-sobre-ola&utm_campaign=website'},
    {src: facebookImg, link: 'https://facebook.com/olaguildgames?utm_source=web&utm_medium=header-sobre-ola&utm_campaign=website'},
]

  return (
    <Flex
      backgroundColor={theme.footer}
      padding={'48px 10px'}
      flexDir={'column'}
      alignItems={'center'}
      >
        <Flex
          flexDir={'column'}
          alignItems={'center'}
          gap={'4px'}
          marginBottom={'48px'}
        >
          <Text
            fontSize={'14px'}
            fontWeight={'400'}
            color={'#fff'}
          >
            Powered by
          </Text>
          < NavbarLogoOla />
        </Flex>
        <Flex
          marginBottom={'48px'}
        >
          <Grid gap={{
                base: '36px',
                md: '80px'
            }}
            rowGap={{
                base: '16px',
                md: '80px'
            }}
            justifyContent='center'
            alignItems='center'
            templateColumns={{
                base: 'repeat(4, 1fr)',
                md: 'repeat(7, 1fr)',
            }}>
              {
                data.map((item, index) => (
                  <GridItem key={index}>
                    <a href={item.link}>
                      <Image src={item.src} />
                    </a>
                  </GridItem>
                ))
              }
            </Grid>
        </Flex>
        <Flex
          gap={{base: '24px', md: '24px', lg: '68px', xl: '68px'}}
          color={theme.footerFontColor}
          marginBottom={'48px'}
          fontSize={'14px'}
          fontWeight={'400'}
          flexDirection={{base: 'column', md: 'column', lg: 'row', xl: 'row'}}
          textAlign={'center'}
        >
          <a href={`https://olagg.io/landing/faqs`}>
            {t('footer.links.faq')}
          </a>
          <a href={`https://olagg.io/posts/tyc`}>
            {t('footer.links.terms_and_conditions')}
          </a>
          <a href={`https://olagg.io/posts/privacidad`}>
            {t('footer.links.privacy_policy')}
          </a>
          <a href={'mailto:soporte@olagg.io'} target="_blank">
            {t('footer.links.support')}
          </a>
        </Flex>
        <Text
          color={theme.footerFontColor}
          fontSize={'14px'}
          fontWeight={'300'}
        >
          {t('footer.copyright')}
        </Text>
    </Flex>
  )
}

export default Footer