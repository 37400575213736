import { useDisconnect } from 'thirdweb/react';
import { useState } from 'react';
import { fetcher, Web3 } from '@olagg/api-hooks';
import { useAuthStore } from '@olagg/store';
import { useActiveAccount, useActiveWallet, useActiveWalletChain } from 'thirdweb/react';
import { useToast } from '@chakra-ui/react';
import { UserWallet } from '@olagg/db-types';
import useCurrentWalletStore from './useCurrentWalletStore';

export const useConnectWallet = () => {
  const { me } = useAuthStore()
  const toast = useToast();
  const { disconnect } = useDisconnect();
  const activeWallet = useActiveWallet();
  const activeAccount = useActiveAccount();
  const activeChain = useActiveWalletChain();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { currentWallet, setCurrentWallet } = useCurrentWalletStore();

  const analytics = () => window.analytics;

  const safeChainName = () => activeChain?.name?.toUpperCase() === "POLYGON MAINNET" ? "POLYGON" : activeChain?.name?.toUpperCase();

  const createWallet = async (address: string) => {
    setIsLoading(true);
    await fetcher(
      Web3.createWallet({
        network: safeChainName(),
        address,
        main: true
      })
    ).then((res: { wallet: UserWallet }) => {
      const { wallet } = res;
      setCurrentWallet(wallet);
      analytics()?.track("Wallet Connect Completed", {
        blockchain_network: activeChain?.name,
        wallet_provider: activeWallet?.id
      });
      analytics()?.identify(`${me?.id}`, {
        wallets: [
          {
            blockchain_network: activeChain?.name,
            wallet_provider: activeWallet?.id,
            wallet_address: address
          }
        ]
      });
    }).catch(error => {
      toast({
        title: 'Error',
        duration: 3000,
        status: 'error',
        description: error.error,
        position: 'top',
        isClosable: true
      });
      console.log(error);
      if (activeWallet) disconnect(activeWallet);
      setCurrentWallet(null);
    }).finally(() => {
      setIsLoading(false);
    });
  }

  const refreshWallet = async (address?: string) => {
    if (!address) return;
    setIsLoading(true);
    await fetcher(
      Web3.myWallets()
    ).then((res: { wallets: UserWallet[] }) => {
      const { wallets } = res;
      if (wallets.length === 0 && address) {
        createWallet(address);
      } else {
        if (wallets[0].address == address) {
          setCurrentWallet(wallets[0]);
        } else {
          createWallet(address);
        }
      }
    }).catch(error => {
      setCurrentWallet(null);
      console.log(error);
    }).finally(() => {
      setIsLoading(false);
    });
  }

  const syncWallet = () => {
    refreshWallet(currentWallet?.address || activeAccount?.address);
  }

  const disconnectWallet = () => {
    if (currentWallet) {
      setIsLoading(true);
      fetcher(
        Web3.deleteWallet(currentWallet.id)
      ).then(() => {
        if (activeWallet) disconnect(activeWallet);
        setCurrentWallet(null);
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        setIsLoading(false);
      });
    }
  }

  return {
    isLoading,
    setIsLoading,
    currentWallet,
    setCurrentWallet,
    syncWallet,
    disconnectWallet
  }
}
