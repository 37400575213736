import { User } from '@olagg/db-types'
import { UserEndpoints } from '../endpoints/endpoints'
import { IUseUsers } from '../interfaces/IUseUsers'
import { useQ } from './useQ'

export const useUsers = ({
  country,
  query,
  referralCountSort,
  offset,
  limit
}: IUseUsers) => {
  const usersResponse = useQ<User[]>(
    UserEndpoints.paginatedUsers({
      country,
      query,
      referralCountSort,
      offset,
      limit
    }),
    {
      revalidateOnFocus: false
    }
  );

  const usersCount = useQ<{count: number}>(
    UserEndpoints.count(),
    {
      revalidateOnFocus: false
    }
  );

  return {
    ...usersResponse,
    count: usersCount?.data?.count
  }
}
