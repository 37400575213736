import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack
} from '@chakra-ui/react'
import { DownArrow, UpArrow } from '@olagg/ui-kit';

import usePartners from '../../hooks/usePartners';
import { useState } from 'react';

const DropdownMenu = ({buttonText, menuOptions, buttonOnClick, buttonStyle, showRightIcon = true }) => {
  const [menuActive, setMenuActive] = useState(false)
  const {theme} = usePartners()

  return (
    <Stack direction={'row'} spacing={4}>
      <Box>
        <Popover
          trigger={'hover'}
          placement={'bottom-start'}
          onOpen={() => setMenuActive(true)}
          onClose={() => setMenuActive(false)}
        >
          <PopoverTrigger>
            <Button
              height="48px"
              borderRadius='6px'
              color={theme.fontColor}
              fontSize='16px'
              fontWeight={menuActive ? 'bold' : '400'}
              bg={theme.cardBg}
              {...buttonStyle}
              _hover={{fontWeight: 'bold' }}
              rightIcon={showRightIcon && (menuActive ? <UpArrow color={theme.fontColor} style={{ marginLeft: '10px' }} /> : <DownArrow color={theme.fontColor} style={{ marginLeft: '10px' }} />)}
              marginRight={'6px'}
              onClick={buttonOnClick ? buttonOnClick : null}
            >
              {buttonText}
            </Button>
          </PopoverTrigger>
          <PopoverContent
            border={0}
            bg={theme.cardBg}
            p={4}
            borderRadius={'6px'}
            width={'auto'}
          >
            {menuOptions.map((option, index) => (
              option.onClick ? (
                <Button
                  padding={'0px'}
                  alignSelf={'start'}
                  key={index}
                  bg={'transparent'}
                  color={theme.fontColor}
                  _hover={{ fontWeight: 'bold' }}
                  fontSize={'16px'}
                  fontWeight={'400'}
                  leftIcon={option.icon}
                  _active={{ bg: theme.cardBg, fontWeight: 'bold' }}
                  onClick={option.onClick}
                >
                  {option.text}
                </Button>
              ) : (
                <Flex
                  padding={'0px'}
                  alignSelf={'start'}
                  key={index}
                  bg={theme.cardBg}
                  color={theme.fontColor}
                  _hover={{ fontWeight: 'bold' }}
                  fontSize={'16px'}
                  fontWeight={'400'}
                  leftIcon={option.icon}
                  _active={{ bg: theme.cardBg, fontWeight: 'bold' }}
                >
                  {option.text}
                </Flex>
              )
            ))
            }
          </PopoverContent>
        </Popover>
      </Box>
    </Stack>
  )
}

export default DropdownMenu