import { Box, Button, Flex, Image, Text, useMediaQuery } from "@chakra-ui/react"
import { useEffect, useState } from "react"

import {XpStatic} from '@olagg/ui-kit/svg/XpStatic'
import avatar from '@olagg/ui-kit/svg/avatar_circle.svg'
import image from './assets/Rogi.png'
import { useAuthStore } from "@olagg/store"
import usePartners from '../../hooks/usePartners';

const User = () => {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')
  const [isLargerThan1025] = useMediaQuery('(min-width: 1025px)')
  const { me, logout } = useAuthStore()
  const {theme, mainColor} = usePartners()
  const [imgAvatar, setImgAvatar] = useState(avatar)

  useEffect(() => {
    setImgAvatar(me?.avatar || avatar)
}, [me])

  return (
    <>
      <Flex
        flexDir={'column'}
        alignItems={'center'}
        width='100%'
        position={'relative'}
        top='-40px'
      >
        <Flex
          width={'100px'}
          height={'100px'}
          borderWidth={{base: '3px', md: '6px'}}
          borderRadius={'50%'}
          borderColor={mainColor}
          marginBottom={{base: '8px', md: '24px'}}
          alignItems={'center'}
          justifyContent={'center'}
          bg={theme.cardBg}
        >
          <Image
            src={imgAvatar}
            width={'full'}
            height={'full'}
            loading={'lazy'}
            borderRadius={'50%'}
            objectFit="cover"
          />
        </Flex>
        <Flex
          alignItems={'center'}
          justifyContent={'center'}
          gap={'10px'}
          flexDirection={isLargerThan768 ? 'row' : 'column'}
          width='100%'
        >
          <Text
            fontSize={{base: '24px', md: '28px'}}
            color={theme.fontColor}
            fontWeight={'700'}
            lineHeight={{base: '34px', md: '46px'}}
            textAlign={'center'}
          >
            {me.name}
          </Text>
          <Flex
            padding={'6px 16px'}
            borderRadius={'200px'}
            bg={mainColor}
            alignItems={'center'}
            gap={'4px'}
          >
            <Text
              fontSize={{base: '24px', md: '28px'}}
              fontWeight={'600'}
              lineHeight={{base: '26px', md: '36px'}}
              color={theme.btnTextColor}
            >
              {me.level.score}
            </Text>
            {
              isLargerThan768 ? (
                <XpStatic color={theme.btnTextColor} width={'34px'} />
              ) : (
                <XpStatic color={theme.btnTextColor} width={'30px'} height={'14px'} />
              )
            }
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

export default User