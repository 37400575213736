import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  useToast
} from "@chakra-ui/react"
import { QUEST_STATUS, QUEST_TYPES } from '@olagg/db-types'
import { useAuthStore, useOnboardingStore } from '@olagg/store'
import { useLocation, useNavigate } from 'react-router-dom'

import { analytics } from "../../../../analytics"
import usePartners from "../../hooks/usePartners"
import useQuest from "../../hooks/useQuest"
import { useContext, useState } from "react"
import { AdTrackingContext } from '../../../../contexts/adTrackingContext'

const QuestModal = ({ quest, isOpen, setIsOpen, reloadQuests }) => {
  const lang = localStorage.getItem('i18n_lang') || 'es'
  const { fcb, fbp } = useContext(AdTrackingContext)
  const { pathname } = useLocation()
  const pathnameExtract = pathname.split(`/`)[1]
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hover, setHover] = useState(false)
  const [visible, setVisible] = useState(false)
  const [verifying, setVerifying] = useState(false)
  const { loggedIn, getMe } = useAuthStore()
  const { openOnboarding } = useOnboardingStore()
  const navigate = useNavigate()
  const { completeQuest, claimQuest } = useQuest()
  const [isOpenSecretModal, setIsOpenSecretModal] = useState(false)
  const { theme, mainColor } = usePartners()

  const toast = useToast()

  const claimed = quest.status === QUEST_STATUS.CLAIMED
  const completed = quest.status === QUEST_STATUS.COMPLETED
  const disabled = quest.status === QUEST_STATUS.DISABLED
  const pending = quest.status === QUEST_STATUS.PENDING
  const adminCompletion = quest.type === QUEST_TYPES.ADMIN_COMPLETION
  const automatic = quest.type === QUEST_TYPES.AUTOMATIC
  const manual = quest.type === QUEST_TYPES.MANUAL
  const oneClick = quest.type === QUEST_TYPES.ONE_CLICK
  const secretWord = quest.type === QUEST_TYPES.SECRET_WORD
  const twitter = quest.type === QUEST_TYPES.TWITTER
  const description = lang === 'es' ? quest.description : quest.extraData?.[lang]?.description || quest.description
  const buttonText = lang === 'es' ? quest.buttonText : quest.extraData?.[lang]?.buttonText || quest.buttonText

  const parent = quest.parents?.[0]

  const dependsOnFilteredStatus = parent && quest.status !== QUEST_STATUS.PENDING || false

  const title = () => {
    const _title = lang === 'es' ? quest.title : quest.extraData?.[lang]?.title || quest.title
    if (quest.recursive) {
      const n = quest.iteration * quest.multiplier
      return _title?.replace('[N]', n.toString())
    }
    return _title
  }

  const onMouseEnterHandler = () => {
    if ((quest.description || dependsOnFilteredStatus) && !claimed) setHover(true)
  }

  const onMouseLeaveHandler = () => setHover(false)

  const onClickVisibleHandler = () => {
    if (!completed && !claimed) setVisible(!visible)
  }

  const goToUrl = () => {
    if (!loggedIn) return openOnboarding()
    if (quest.action.startsWith('https://')) window.open(quest.action, '_blank')
    else window.open(`${import.meta.env.VITE_APP_URL}${quest.action}`, '_blank')
  }

  const handleCompleteQuest = async () => {
    if (!loggedIn) return openOnboarding()
    if (secretWord) return handleModalSecretWord()
    try {
      setVerifying(true)

      let props = {
        quest_id: quest.id,
        quest_name: quest.title,
        quest_xp: quest.score,
        owner: pathnameExtract,
        user_agent: navigator.userAgent,
      }
      if (fcb != null) props.fbc = fcb
      if (fbp != null) props.fbp = fbp

      if (oneClick) {
        analytics()
          ?.track('Quest Started', props)
      }

      await completeQuest(quest)

      analytics().track('Quest Verification Requested', props)

      setVerifying(false)
      reloadQuests()
      setIsOpen(false)
    } catch (error) {
      setVerifying(false)

      // toast({
      //   title: "Error",
      //   description: 'Ocurrió un error, intenta nuevamente!',
      //   status: "error",
      //   duration: 9000,
      //   isClosable: true,
      // })
    }
  }

  const handleClaim = async () => {
    if (!loggedIn) return openOnboarding()
    setVerifying(true)
    await claimQuest(quest)
    setVerifying(false)
    reloadQuests()
    getMe()
  }

  const onSubmitSecretWord = () => {
    reloadQuests()
    getMe()
  }

  const handleModalSecretWord = () => {
    let props = {
      quest_id: quest.id,
      quest_name: quest.title,
      quest_xp: quest.score,
      owner: pathnameExtract,
      user_agent: navigator.userAgent,
    }
    if (fcb != null) props.fbc = fcb
    if (fbp != null) props.fbp = fbp
    analytics()
      ?.track('Quest Started', props)
    setIsOpenSecretModal(true)
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          mx={5}
          bg={'#fff'}
        >
          <ModalCloseButton
            color={'#000'}
          />
          <ModalBody py={8}>
            <Heading
              fontSize={'24px'}
              fontWeight='600'
              lineHeight='34px'
              color={'#000'}
              pb={'16px'}
              textAlign={'center'}
              borderBottom='1px solid'
              borderColor={mainColor}
            >
              {title()}
            </Heading>
            <Flex
              flexDir='column'
              alignItems={'center'}
              justifyContent={'center'}
              marginTop={'24px'}
              lineHeight={'30px'}
              textAlign={'center'}
            >
              {
                !dependsOnFilteredStatus && quest.description && (
                  <Text
                    fontSize={'20px'}
                    fontWeight={'400'}
                    marginBottom={'24px'}
                    color={'#000'}
                  >
                    {description}
                  </Text>
                )
              }
              {
                (dependsOnFilteredStatus && (pending || disabled)) && (
                  <Text
                    fontSize={'20px'}
                    fontWeight={'400'}
                    marginBottom={'24px'}
                    color={'#000'}
                  >
                    Primero tienes que completar la misión: <br />
                    <strong>{parent?.title}</strong>
                  </Text>
                )
              }
              {quest.action &&
                (pending || disabled) &&
                (automatic || (adminCompletion)) && !dependsOnFilteredStatus && (
                  <Button
                    w={'full'}
                    bg={'#000'}
                    color={'#fff'}
                    onClick={() => {
                      let props = {
                        quest_id: quest.id,
                        quest_name: quest.title,
                        quest_xp: quest.score,
                        owner: pathnameExtract,
                        user_agent: navigator.userAgent,
                      }
                      if (fcb != null) props.fbc = fcb
                      if (fbp != null) props.fbp = fbp
                      analytics()
                        ?.track('Quest Started', props)
                      goToUrl()
                    }}
                    disabled={disabled && loggedIn}
                  >
                    {buttonText}
                  </Button>
                )}
              {/* DependsOn */}
              {quest.action && dependsOnFilteredStatus &&
                (automatic || adminCompletion) && (
                  <Button
                    w={'full'}
                    bg={'#000'}
                    color={theme.background}
                    _hover={{ backgroundColor: mainColor }}
                    disabled
                  >
                    {buttonText}
                  </Button>
                )}
              {!dependsOnFilteredStatus && (pending || disabled) &&
                (manual || oneClick || secretWord || twitter) && (
                  <Button
                    variant={'filledGradient'}
                    bg={'#000'}
                    w={'full'}
                    color={'white'}
                    fontSize="20px"
                    onClick={handleCompleteQuest}
                    disabled={verifying || (disabled && loggedIn)}
                  >
                    {verifying ? (
                      <Spinner speed="0.85s" />
                    ) : (
                      (oneClick || secretWord) ? buttonText : 'Verificar'
                    )}
                  </Button>
                )}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default QuestModal