import { Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export const Laguna = ({
  on = true
}: {
  on?: boolean
}) => {
  const { t } = useTranslation();

  const LagunaOn = (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6384_12855)">
        <rect width="30" height="30" rx="15" fill="url(#paint0_linear_6384_12855)"/>
        <path d="M-34.7158 14.375C3.95216 20.4018 35.9913 19.2857 64.7161 14.375V30H-34.7158V14.375Z" fill="white"/>
      </g>
      <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" stroke="url(#paint1_linear_6384_12855)"/>
      <defs>
        <linearGradient id="paint0_linear_6384_12855" x1="0" y1="30" x2="30" y2="30" gradientUnits="userSpaceOnUse">
          <stop stopColor="#4E10FF"/>
          <stop offset="1" stopColor="#AB17DF"/>
        </linearGradient>
        <linearGradient id="paint1_linear_6384_12855" x1="0" y1="30" x2="30" y2="30" gradientUnits="userSpaceOnUse">
          <stop stopColor="#4E10FF"/>
          <stop offset="1" stopColor="#AB17DF"/>
        </linearGradient>
        <clipPath id="clip0_6384_12855">
          <rect width="30" height="30" rx="15" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );

  const LagunaOff = (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6393_34817)">
        <rect width="30" height="30" rx="15" fill="#E2E8F0"/>
        <path d="M-34.7158 14.375C3.95216 20.4018 35.9913 19.2857 64.7161 14.375V30H-34.7158V14.375Z" fill="#A0AEC0"/>
      </g>
      <defs>
        <clipPath id="clip0_6393_34817">
          <rect width="30" height="30" rx="15" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );

  return (
    <Flex zIndex='2' justifyContent='center' flexDirection='column' alignItems='center'>
      {on ? LagunaOn : LagunaOff}
      <Text
        fontSize='0.625rem' textAlign='center'
        fontWeight={on ? '600' : '400'}
        color={on ? 'black' : 'gray.600'}
      >
        {t('TokenProgressBar.labels.lagoon')}
      </Text>
    </Flex>
  )
}
