import { UserWallet } from '@olagg/db-types'
import create from 'zustand'

export interface CurrentWalletState {
  currentWallet: UserWallet | null
  setCurrentWallet: (wallet: UserWallet | null) => Promise<void>
}

const useCurrentWalletStore = create<CurrentWalletState>()(
  (set, get) => ({
    currentWallet: null,
    setCurrentWallet: async (wallet: UserWallet | null) => {
      try {
        set(state => ({
          ...state,
          currentWallet: wallet
        }))
      } catch (e) {
        set(state => ({
          ...state,
          currentWallet: null
        }))
      }
    }
  })
)

export default useCurrentWalletStore;
