import { Button } from "@chakra-ui/react";
import { RouletteValue } from "@olagg/db-types";
import useRoulette from "./useRoulette";
import { useTranslation } from 'react-i18next';

export const SpinButton = ({
  callback
}: {
  callback?: (result: RouletteValue) => void
}) => {
  const { t } = useTranslation();
  const { spinRoulette, isSpinning } = useRoulette();

  return (
    <Button 
      w='full' variant='questsDefault'
      isDisabled={isSpinning}
      onClick={() => spinRoulette(callback)}
    >
      {t('quests.roulette.spin')}
    </Button>
  )
}
