import { GameCredentials } from '@olagg/db-types'

export const sortFieldsToComplete = (
  credentials?: GameCredentials
): string[] => {
  if (!credentials) return []
  let fieldsToComplete: string[] = []
  credentials.userRequired.forEach(value => {
    if (!credentials[value as keyof GameCredentials])
      fieldsToComplete.push(value)
  })
  return fieldsToComplete
}

export const isMobile = (
  window: Window & typeof globalThis
): boolean =>
  window.matchMedia('only screen and (max-width: 480px)').matches

export const shortenString = ({
  string,
  characters
}: {
  string: string
  characters: number
}): string => {
  if (string?.length > characters)
    return string.split('').slice(0, characters).join('') + '...'
  return string
}

export const getMonthName = (date: Date) =>
  new Date(date).toLocaleString('default', { month: 'long' })

export const getMinutesDiff = ({
  arg1,
  arg2
}: {
  arg1: Date
  arg2: Date
}) => {
  let diff = (arg1.getTime() - arg2.getTime()) / 1000
  diff /= 60
  return Math.abs(Math.round(diff))
}

const linearInterval = ({
  duration,
  range,
  current
}: {
  duration: number
  range: number
  current: number
}) => ((duration * 2) / Math.pow(range, 2)) * current

export const animateValue = ({
  start,
  end,
  duration,
  callback
}: {
  start: number
  end: number
  duration: number
  callback: (arg: number) => void
}) => {
  let range = end - start
  let current = start
  let increment = end > start ? 1 : -1

  let step = () => {
    current += increment

    if (current !== end) {
      setTimeout(
        step,
        linearInterval({ duration, range, current })
      )
    }

    callback(current)
  }

  setTimeout(
    step,
    linearInterval({ duration, range, current: start })
  )
}

export const checkIfImageExists = (url: string) => {
  const img = new Image();
  img.src = url;
  let status = false
  if (img.complete) {
    status = true
  } else {
    img.onload = () => {
      status = true
    };

    img.onerror = () => {
      status = false
    };
  }
  return status
};

export const sortQuests = (questData: Array<any>) => {
  let completedQuests = sortQuestsByDate(questData.filter(e => e.status == "COMPLETED"));
  let pendingQuests = sortQuestsByDate(questData.filter(e => e.status == "PENDING"));
  let disableQuests = sortQuestsByDate(questData.filter(e => e.status == "DISABLED"));
  let claimedQuests = sortQuestsByDate(questData.filter(e => e.status == "CLAIMED"));

  return [...completedQuests, ...pendingQuests, ...disableQuests, ...claimedQuests];
}

export const sortQuestsByDate = (questData: Array<any>) => {
  questData.sort((a, b) => {
    let dateA = Date.parse(a.createdAt);
    let dateB = Date.parse(b.createdAt);

    if (dateA > dateB) return 1;
    if (dateA < dateB) return -1;
    return 0;
  });

  return questData;
}

export const parseDate = (publication_date: string, includeTime = false) => {
  let dateObj = new Date(publication_date);

  let day = dateObj.getUTCDate();
  let month = dateObj.toLocaleString('es-ES', { month: 'short' });
  let year = dateObj.getFullYear();
  let formattedDate = `${day} ${month} ${year}`

  if (includeTime) formattedDate = formattedDate + ` ${dateObj.toLocaleTimeString('es-ES')}`;

  return formattedDate
}
