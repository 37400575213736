import { Box, Button, Center, Input, ModalHeader, useToast, Text, ModalFooter } from "@chakra-ui/react";
import { useAuthStore, useQuestsStore } from "@olagg/store";
import Modal from "../../../components/Modal/Modal";
import { useTranslation } from "react-i18next";
import { fetcher, UserEndpoints } from "@olagg/api-hooks";
import { QUEST_STATUS } from "@olagg/db-types";

const UpdateUserAvatarModal = () => {
    const { me: user, getMe } = useAuthStore();
    const { activeQuest: quest, setActiveQuest } = useQuestsStore();
    const isOpen = quest?.trigger === 'user.name.set' && quest?.status === QUEST_STATUS.PENDING;
    const onClose = () => setActiveQuest(null);
    const header = <ModalHeader>{quest.title}</ModalHeader>;
    const { t } = useTranslation();
    const toast = useToast();

    const onSubmit = () => {
        fetcher(UserEndpoints.update(user.id, { name: user.name }))
            .then(async () => {
                toast({
                    title: t('myAccount.profile.editModal.success'),
                    colorScheme: 'olaggPink',
                    position: 'bottom-right',
                    duration: 2000,
                    isClosable: true
                })
                setActiveQuest({ ...quest, status: QUEST_STATUS.COMPLETED })
                await getMe()
            })
            .catch(() => {
                toast({
                    title: t('myAccount.profile.editModal.error.tryAgain'),
                    status: 'error',
                    position: 'bottom-right',
                    duration: 2000,
                    isClosable: true
                })
            })
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'sm'} modalHeader={header}>
            <Box p={6}>
                <Text fontSize="16px" fontWeight="600" color="gray.500" textAlign={'center'} pb={4}>
                    {quest.description}
                </Text>
                <Center position="relative">
                    <Input
                        name="username"
                        placeholder={t('myAccount.profile.editModal.username')}
                        defaultValue={user?.name || ''}
                        onChange={(e) => user.name = e.target.value}
                    />
                </Center>
            </Box>
            <ModalFooter>
                <Button
                    p={'0 10px'}
                    w="full"
                    variant="filledGradient"
                    fontSize="16px"
                    fontWeight="600"
                    cursor={'pointer'}
                    onClick={onSubmit}
                >
                    {t('myAccount.profile.editModal.saveChanges')}
                </Button>
            </ModalFooter>
        </Modal>
    )
};

export default UpdateUserAvatarModal;