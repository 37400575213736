import { Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export const Mar = ({
  on = true
}: {
  on?: boolean
}) => {
  const { t } = useTranslation();

  const MarOn = (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6384_12932)">
        <rect width="30" height="30" rx="15" fill="url(#paint0_linear_6384_12932)"/>
        <path d="M29.2786 30C29.4699 30 29.6534 29.925 29.7887 29.7916C29.924 29.6581 30 29.4771 30 29.2884V20.3344C30 20.1851 29.8761 20.0586 29.7249 20.0511C27.8342 19.9565 26.3832 18.9006 26.3832 17.8563C26.3832 17.0346 26.69 16.4548 26.9873 16.0861C27.1474 15.8874 26.9826 15.5952 26.7274 15.6275C22.9125 16.1109 23.85 20.0579 19.2266 20.0579C17.2034 20.0579 15.6098 18.9508 15.6098 17.8563C15.6098 17.0346 15.9165 16.4548 16.2138 16.0861C16.374 15.8874 16.2092 15.5952 15.9539 15.6275C12.139 16.1109 13.0766 20.0579 8.45317 20.0579C6.42996 20.0579 4.83634 18.9508 4.83634 17.8563C4.83634 17.0346 5.1431 16.4548 5.44038 16.0861C5.60052 15.8874 5.43572 15.5952 5.18051 15.6275C1.3656 16.1109 2.30312 20.0579 -2.32027 20.0579C-4.34348 20.0579 -5.9371 18.9508 -5.9371 17.8563C-5.9371 17.0346 -5.63034 16.4548 -5.33306 16.0861C-5.17291 15.8874 -5.33771 15.5952 -5.59293 15.6275C-9.40783 16.1109 -8.47031 20.0579 -13.0937 20.0579L-13.1249 29.1779C-13.1318 29.6299 -12.7622 30 -12.3038 30H29.2786Z" fill="white"/>
      </g>
      <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" stroke="url(#paint1_linear_6384_12932)"/>
      <defs>
        <linearGradient id="paint0_linear_6384_12932" x1="0" y1="30" x2="30" y2="30" gradientUnits="userSpaceOnUse">
          <stop stopColor="#4E10FF"/>
          <stop offset="1" stopColor="#AB17DF"/>
        </linearGradient>
        <linearGradient id="paint1_linear_6384_12932" x1="0" y1="30" x2="30" y2="30" gradientUnits="userSpaceOnUse">
          <stop stopColor="#4E10FF"/>
          <stop offset="1" stopColor="#AB17DF"/>
        </linearGradient>
        <clipPath id="clip0_6384_12932">
          <rect width="30" height="30" rx="15" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );

  const MarOff = (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6393_34821)">
        <rect width="30" height="30" rx="15" fill="#E2E8F0"/>
        <path d="M29.2786 30C29.4699 30 29.6534 29.925 29.7887 29.7916C29.924 29.6581 30 29.4771 30 29.2884V20.3344C30 20.1851 29.8761 20.0586 29.7249 20.0511C27.8342 19.9565 26.3832 18.9006 26.3832 17.8563C26.3832 17.0346 26.69 16.4548 26.9873 16.0861C27.1474 15.8874 26.9826 15.5952 26.7274 15.6275C22.9125 16.1109 23.85 20.0579 19.2266 20.0579C17.2034 20.0579 15.6098 18.9508 15.6098 17.8563C15.6098 17.0346 15.9165 16.4548 16.2138 16.0861C16.374 15.8874 16.2092 15.5952 15.9539 15.6275C12.139 16.1109 13.0766 20.0579 8.45317 20.0579C6.42996 20.0579 4.83634 18.9508 4.83634 17.8563C4.83634 17.0346 5.1431 16.4548 5.44038 16.0861C5.60052 15.8874 5.43572 15.5952 5.18051 15.6275C1.3656 16.1109 2.30312 20.0579 -2.32027 20.0579C-4.34348 20.0579 -5.9371 18.9508 -5.9371 17.8563C-5.9371 17.0346 -5.63034 16.4548 -5.33306 16.0861C-5.17291 15.8874 -5.33771 15.5952 -5.59293 15.6275C-9.40783 16.1109 -8.47031 20.0579 -13.0937 20.0579L-13.1249 29.1779C-13.1318 29.6299 -12.7622 30 -12.3038 30H29.2786Z" fill="#A0AEC0"/>
      </g>
      <defs>
        <clipPath id="clip0_6393_34821">
          <rect width="30" height="30" rx="15" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );

  return (
    <Flex zIndex='2' justifyContent='center' flexDirection='column' alignItems='center'>
      {on ? MarOn : MarOff}
      <Text
        fontSize='0.625rem' textAlign='center'
        fontWeight={on ? '600' : '400'}
        color={on ? 'black' : 'gray.600'}
      >
        {t('TokenProgressBar.labels.sea')}
      </Text>
    </Flex>
  )
}
