import { Flex, Button, Popover, PopoverBody, PopoverContent, PopoverTrigger, Box, PopoverArrow } from '@chakra-ui/react';
import { AiOutlineInfoCircle } from "react-icons/ai"
import { createThirdwebClient } from "thirdweb";
import { ConnectButton, useConnect, useActiveWalletConnectionStatus } from 'thirdweb/react';
import { inAppWallet, createWallet as createThirdwebWallet, walletConnect } from "thirdweb/wallets";
import { mumbai, polygon } from "thirdweb/chains";
import { OSName } from './utils/operationSystem';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import s from './styles/ConnectButton.module.css';

export const ConnectButtons = ({
  setIsLoading,
  direction = 'row',
  disconnectWallet
}: {
  setIsLoading: (val: boolean) => void,
  direction?: 'row' | 'column',
  disconnectWallet: () => void
}) => {
  const { t } = useTranslation();
  const { connect } = useConnect();

  const status = useActiveWalletConnectionStatus();
  const lang = localStorage.getItem('i18n_lang') || navigator.language.slice(0, 2);
  const locale = lang === 'es' ? 'es_ES' : 'en_US'; // portuguese not available in thirdweb

  const thirdwebClient = createThirdwebClient({
    clientId: import.meta.env.VITE_THIRDWEB_CLIENT_ID,
  });

  const chain = (import.meta.env.VITE_OLAGG_CHAIN === 'Mumbai' ? mumbai : polygon);

  const wallets = () => {
    const wallets = [
      walletConnect()
    ];
    if (OSName() == 'macOS' || OSName() == 'Windows' || OSName() == 'Linux') {
      wallets.push(createThirdwebWallet("io.metamask"));
    }
    return wallets;
  }

  const connectInAppWallet = () => {
    setIsLoading(true);
    connect(async () => {
      const wallet = inAppWallet();
      await wallet.connect({
        client: thirdwebClient,
        chain: chain,
        strategy: "auth_endpoint",
        payload: Cookies.get('auth._token.local') ?? '',
        encryptionKey: "key",
      });
      return wallet;
    })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }

  const ConnectOlaWalletButton = () => (
    <Button variant='outlinedGradient' w='full' color='white' onClick={() => connectInAppWallet()} gap='1'>
      {t('ConnectWallet.ConnectButtons.connectWithOlaButton.label')}
      <Popover boundary='scrollParent'>
        <PopoverTrigger>
          <Box zIndex='3' onClick={e => e.stopPropagation()} position='relative' mt='-2px'>
            <AiOutlineInfoCircle cursor='pointer' fontSize='1.3rem' color='white' />
          </Box>
        </PopoverTrigger>
        <PopoverContent
          bg='#717171' color='white' border='none'
          _focusVisible={{ outline: 'none' }} fontSize='.9rem'
          textAlign='left' fontWeight='400'
        >
          <PopoverArrow bg='#717171' />
          <PopoverBody whiteSpace='break-spaces'>
            <div dangerouslySetInnerHTML={
              { __html: t('ConnectWallet.ConnectButtons.connectWithOlaButton.infoPopover', { 'interpolation': { 'escapeValue': false } }) }
            } />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Button>
  )

  return (
    <Flex mb='2' flexWrap='wrap' gap='1' justifyContent='center' alignItems='center' direction={direction}>
      <ConnectButton
        appMetadata={{
          name: 'Ola GG',
          url: 'https://olagg.io'
        }}
        client={thirdwebClient}
        wallets={wallets()}
        chain={chain}
        showAllWallets={false}
        locale={locale}
        walletConnect={{
          projectId: import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID
        }}
        connectButton={{
          label: t('ConnectWallet.ConnectButtons.mainButton.label'),
          className: s.connectButton,
        }}
        connectModal={{
          showThirdwebBranding: false,
        }}
        detailsButton={{
          className: s.detailsButton,
        }}
        detailsModal={{
          hideBuyFunds: true,
          hideSwitchWallet: true,
          showTestnetFaucet: false,
        }}
        onDisconnect={disconnectWallet}
      />
      {status === 'connected' ? null : <ConnectOlaWalletButton />}
    </Flex>
  )
}
