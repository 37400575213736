import { Box, Center, Heading, useMediaQuery, Flex } from '@chakra-ui/react'
import { useGame } from '@olagg/api-hooks'
import { Game } from '@olagg/db-types'
import { FC } from 'react'
import { FaArrowLeft, FaCog, FaChevronRight } from 'react-icons/fa'
import {
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom'
import { ROUTES } from '../../routes'
import NavbarDropdown from '../Dropdown/NavbarDropdown'

const SecondaryNavbar: FC = () => {
  const { slug } = useParams()
  const { pathname } = useLocation()
  const { data: game } = useGame(slug || '')

  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')

  const navigate = useNavigate()

  const buildHeading = (game?: Game) => {
    if (game?.title) return game.title
    if (pathname.includes(ROUTES.MY_PROFILE_PICTURE)) {
      return 'Cambiar avatar'
    } else {
      return 'Mi cuenta'
    }
  }

  const buildSubHeading = (game?: Game) => {
    if (pathname.includes(ROUTES.MY_PROFILE_DATA))
      return 'Perfil'
    if (pathname.includes(ROUTES.MY_PROFILE)) return 'Perfil'
    if (pathname.includes(ROUTES.SCHOLARSHIPS))
      return 'Becas'
    if (pathname.includes(ROUTES.REWARDS))
      return 'Recompensas'
    if (pathname.includes(ROUTES.TOKENS))
      return 'Tokens'
    if (pathname.includes(ROUTES.REFERRALS))
      return 'Referidos'
    if (pathname.includes(ROUTES.SECURITY))
      return 'Seguridad'
    return ''
  }

  const heading = buildHeading(game)
  const subHeading = buildSubHeading()

  return (
    <Box
      maxWidth={import.meta.env.VITE_LAYOUT_MAXWIDTH}
      margin={{ base: '0 auto', md: "20px auto" }}
      position="relative"
      top="25px"
      padding={{ base: '10px', md: 0 }}
    >
      <Center color="white">
        <Heading
          ml={5}
          mt={1}
          fontSize="24px"
          fontFamily="body"
          fontWeight="600"
          flex="2"
        >
          <Flex
            alignItems='center'
            gap='10px'
          >
            {heading}
            {!isLargerThan768 && (
              <>
                <FaChevronRight size='16px' /> {subHeading}
              </>
            )}
          </Flex>
        </Heading>
        {/* <NavbarDropdown logo={<FaCog size={25} />} /> */}
      </Center>
    </Box>
  )
}

export default SecondaryNavbar
