import { Flex, Text, useMediaQuery, useToast, } from '@chakra-ui/react';
import { Logout2, Share } from '@olagg/ui-kit';
import Check from "@olagg/ui-kit/svg/Check";
import DropdownMenu from './DropdownMenu';
import MobileMenu from './MobileMenu';
import { useAuthStore } from "@olagg/store";
import usePartners from '../../hooks/usePartners';
import { useTranslation } from 'react-i18next';
import { useReferrals } from '../Referral/hooks/useReferrals';
import { useNavigate } from 'react-router-dom';

const Menu = () => {
  const { i18n, t } = useTranslation()
  const [isLargerThan1025] = useMediaQuery('(min-width: 1025px)')
  const { logout } = useAuthStore()
  const toast = useToast()
  const {theme} = usePartners()
  const { urlPath } = usePartners()
  const { ActionType, generateReferrerId } = useReferrals();

  const referrerId = generateReferrerId(ActionType.REFER_FRIEND);
  const referUrl = `${import.meta.env.VITE_BASE_URL}/${i18n.language}/login${referrerId}&partner=${urlPath}&redirect=/${urlPath}`;

  const navigate = useNavigate()
  
  const changeLang = (lang) => {
    const currentPath = window.location.pathname.split('/').slice(2).join('/');
    i18n.changeLanguage(lang);
    const searchParams = location.search;
    localStorage.setItem('i18n_lang', lang);
    navigate(`/${lang}/${currentPath}${searchParams}`, { replace: true });

  }

  const copyReferral = () => {
    navigator.clipboard.writeText(referUrl);
    toast({
        title: t('quests.referral.copy_text'),
        render: () => (
          <Flex
            padding={'10px'}
            borderRadius={'6px'}
            borderWidth={'1px'}
            borderColor={'#25855A'}
            bg={theme.cardBg}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Check color={'#25855A'} />
            <Text
              marginLeft={'10px'}
              fontSize={'16px'}
              fontWeight={'500'}
              color={theme.fontColor}
            >
              {t('quests.referral.copy_text')}
            </Text>
          </Flex>
        ),
        position: 'top',
        duration: 5000,
        isClosable: false,
    });
  }

  return (
    <>
    <Flex
      position={'absolute'}
      alignItems={'center'}
      gap={'12px'}
      right={{base: '24px', lg: '100px'}}
    >
      {
        isLargerThan1025 ? (
          <>
            <DropdownMenu
              buttonText={i18n.language.toLocaleUpperCase()}
              menuOptions={[
                { text: 'Español', onClick: () => changeLang('es') },
                { text: 'English', onClick: () => changeLang('en') },
                { text: 'Português', onClick: () => changeLang('pt') },
              ]}
            />
            <DropdownMenu
              buttonText={<Share width={'24px'} height={'24px'} color={theme.fontColor} />}
              buttonStyle={{width: '48px', height: '48px', justifyContent: 'center', alignItems: 'center', backgroundColor: theme.cardBg, borderRadius: '6px'}}
              menuOptions={[
                { text: t('quests.tool_tip_share')},
              ]}
              showRightIcon={false}
              buttonOnClick={copyReferral}
            />
            <DropdownMenu
              buttonText={<Logout2 width={'20px'} height={'20px'} color={theme.fontColor} />}
              buttonStyle={{width: '48px', height: '48px', justifyContent: 'center', alignItems: 'center', backgroundColor: theme.cardBg, borderRadius: '6px'}}
              menuOptions={[
                { text: t('quests.tool_tip_logout')},
              ]}
              showRightIcon={false}
              buttonOnClick={logout}
            />
          </>
        ) : (
          <MobileMenu />
        )
      }
    </Flex>
    </>
  )
}

export default Menu