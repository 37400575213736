import { Flex, Text } from "@chakra-ui/react"
import { useEffect, useLayoutEffect, useRef, useState } from "react"

import usePartners from "../../hooks/usePartners"
import { useTranslation } from "react-i18next"

const Visit = () => {
  const {theme} = usePartners()
  const layoutRef = useRef(null)
  const [layoutWidth, setLayoutWidth] = useState(1366)
  const {t} = useTranslation()

  useLayoutEffect(() => {
      setLayoutWidth(layoutRef?.current?.clientWidth)
  }, []);

  useEffect(() => {
      function handleWindowResize() {
          setLayoutWidth(layoutRef?.current?.clientWidth);
      }
      window.addEventListener('resize', handleWindowResize);
      return () => {
          window.removeEventListener('resize', handleWindowResize);
      };
  }, [])
  return (
    <Flex
      w={'full'}
      flexDir={'column'}
      position={'relative'}
      ref={layoutRef}
      marginTop={'100px'}
    >
      <Flex
        width='100vw'
        left={`calc(calc(calc(${layoutWidth}px - 100vw) + calc(calc(100vw - ${layoutWidth}px) / 2)))`}
        position='relative'
      >
        <Flex
          w={'full'}
          h={'auto'}
          bg={theme.footerVisit}
          flexDir={'column'}
          padding={{base: '48px 16px', md: '48px 16px', lg: '68px', xl: '68px 0px'}}
        >
          <Text
            color={theme.footerVisitFontColor}
            fontSize={{base: '14px', md: '14px', lg: '20px', xl: '24px'}}
            fontWeight={'400'}
            textAlign={'center'}
          >
            {t('quests.footer.visit.text_1')}
          </Text>

          <Text
            color={theme.footerVisitFontColor}
            fontSize={{base: '14px', md: '14px', lg: '20px', xl: '24px'}}
            fontWeight={'400'}
            textAlign={'center'}
          >
            {t('quests.footer.visit.text_2')} <a href={'https://olagg.io/'} style={{fontWeight: '700', textDecorationLine: 'underline'}}>olagg.io</a> {t('quests.footer.visit.text_3')}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Visit