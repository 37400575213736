import {
    Box,
    Button,
    Flex,
    Text
} from "@chakra-ui/react"
import { useContext, useEffect, useState } from "react"

import Modal from "../../../components/Modal/Modal"
import OlaGGFormInput from "../../../components/Form/OlaggFormInput"
import OlaggForm from "../../../components/Form/OlaggForm"
import OlaggMarkdown from "@olagg/ui-kit/OlaggMarkdown"
import { analytics } from "../../../analytics"
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import usePartners from "../hooks/usePartners"
import useQuest from "../hooks/useQuest"
import { useTranslation } from "react-i18next"
import { AdTrackingContext } from '../../../contexts/adTrackingContext'

const SecretWordModal = ({ handleClaim, quest, isOpen, setIsOpen, onSubmit }) => {
    const { fcb, fbp } = useContext(AdTrackingContext)
    const { completeQuest, getUserQuest } = useQuest()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [secretWordError, setSecretWordError] = useState<boolean>(false)
    const [currentAttempt, setCurrentAttempt] = useState<number>(quest.secretWordAttempt + 1)
    const { t, i18n } = useTranslation()
    const { mainColor } = usePartners()
    const { pathname } = useLocation()
    const pathnameExtract = pathname.split(`/`)[1]

    const useFormMethods = useForm({
        mode: 'onChange'
    })

    const closeModal = () => {
        if (limittedAttempts && pendingAttempts === 0) onSubmit() // update quest card status if no more attempts left
        setIsOpen(false)
    }

    const updateCurrentAttempt = () => {
        getUserQuest(quest.id)
            .then(res => {
                if (res.secret_word_attempt >= quest.secretWordMaxAttempts) { // trigger analytics if attempts exhausted
                    let props = {
                        quest_id: quest.id,
                        quest_name: quest.title,
                        quest_xp: quest.score,
                        owner: pathnameExtract,
                        user_agent: navigator.userAgent,
                    }
                    if (fcb != null) props.fbc = fcb
                    if (fbp != null) props.fbp = fbp
                    analytics().track('Quest Failed', props)
                }
                setCurrentAttempt(res.secret_word_attempt + 1)
            })
    }

    const submit = async (data) => {
        try {
            setIsLoading(true)
            let props = {
                quest_id: quest.id,
                quest_name: quest.title,
                quest_xp: quest.score,
                owner: pathnameExtract,
                user_agent: navigator.userAgent,
            }
            if (fcb != null) props.fbc = fcb
            if (fbp != null) props.fbp = fbp
            analytics().track('Quest Verification Requested', props)
            await completeQuest(quest, data).then(r => {
                if (r === false) {
                    setSecretWordError(true)
                    updateCurrentAttempt()
                } else {
                    setIsOpen(false)
                    handleClaim()
                }
            })
        } catch (error) {
            console.log('ERROR');
            setSecretWordError(true)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        setSecretWordError(false)
        useFormMethods.reset()
    }, [isOpen])

    const limittedAttempts = quest.secretWordMaxAttempts > 0;
    const unlimittedAttempts = !limittedAttempts;
    const pendingAttempts = quest.secretWordMaxAttempts - currentAttempt + 1;

    return (
        <>
            <Modal
                isOpen={isOpen} onClose={() => closeModal()}
            >
                <Box p='5'>
                    <Box py='2' borderBottom='1px solid' borderColor={mainColor}>
                        <Text textAlign='center' fontSize='1.5rem' fontWeight='600'>
                            {
                                i18n.language === 'es' ? quest?.title : (i18n.language === 'en' ? (quest.extraData.en?.title && quest.extraData.en?.title) || quest?.title : (quest.extraData.pt?.title && quest.extraData.pt?.title) || quest?.title)
                            }
                        </Text>
                    </Box>
                    <OlaggMarkdown baseFontSize='1em'>
                        {
                            quest?.secretWordInstructions ?
                                i18n.language === 'es' ? quest?.secretWordInstructions : (i18n.language === 'en' ? (quest.extraData.en?.secretWordInstructions && quest.extraData.en?.secretWordInstructions) || quest?.secretWordInstructions : (quest.extraData.pt?.secretWordInstructions && quest.extraData.pt?.secretWordInstructions) || quest?.secretWordInstructions) :
                                i18n.language === 'es' ? quest?.title : (i18n.language === 'en' ? (quest.extraData.en?.title && quest.extraData.en?.title) || quest?.title : (quest.extraData.pt?.title && quest.extraData.pt?.title) || quest?.title)
                        }
                    </OlaggMarkdown>
                    <OlaggForm
                        useFormMethods={useFormMethods}
                        onSubmit={submit}
                    >
                        <OlaGGFormInput
                            labelStyles={{ color: 'olaggPurple.100', fontWeight: 'bold' }}
                            description={limittedAttempts && pendingAttempts ? `${t('secretWord.modal.attempt')} ${currentAttempt} ${t('secretWord.modal.of')} ${quest.secretWordMaxAttempts}` : ''}
                            chakraStyles={{ color: 'black', fontWeight: 'bold', borderColor: '#000' }}
                            controlName='secretWord'
                            required={true}
                            placeholder={t('secretWord.modal.placeHolder')}
                        />
                        <Box color="red" fontWeight='bold' mb='3px' mt='-10px' minH='24px'>
                            {secretWordError && <Text fontSize='14px'>
                                {unlimittedAttempts ?
                                    t('secretWord.modal.error') :
                                    pendingAttempts > 0 ?
                                        `${t('secretWord.modal.error')} ${t('secretWord.modal.you_have')} ${pendingAttempts} ${t('secretWord.modal.more_attempts')}` :
                                        t('secretWord.modal.exhausted')
                                }
                            </Text>}
                        </Box>
                        <Flex gap='10px'>
                            {(unlimittedAttempts || pendingAttempts > 0) &&
                                <Button
                                    p={'0 10px'}
                                    w="full"
                                    bg={'#000'}
                                    fontSize="16px"
                                    fontWeight="600"
                                    cursor={'pointer'}
                                    type='submit'
                                    isLoading={isLoading}
                                    disabled={isLoading}
                                    color={'#fff'}
                                >
                                    {t('secretWord.modal.confirm')}
                                </Button>
                            }
                        </Flex>
                    </OlaggForm>
                </Box>
            </Modal>
        </>
    )
}

export default SecretWordModal