export const Check = ({
  height = '24px',
  width = '25px',
  color = 'white',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.5 15.586L7.20697 12.293L5.79297 13.707L10.5 18.414L20.207 8.70697L18.793 7.29297L10.5 15.586Z"
        fill={color}
      />
    </svg>
  )
}

export default Check
