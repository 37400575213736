import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useToast
} from "@chakra-ui/react"
import { Dots, Globe, Logout2, Share } from '@olagg/ui-kit';

import Check from "@olagg/ui-kit/svg/Check"
import { useAuthStore } from "@olagg/store"
import usePartners from '../../hooks/usePartners';
import { useReferrals } from '../Referral/hooks/useReferrals';
import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom";

const MobileMenu = () => {
  const languages = [
    { name: 'Español', value: 'es' },
    { name: 'English', value: 'en' },
    { name: 'Portugués', value: 'pt' }
  ]

  const { t, i18n } = useTranslation()
  const activeLanguage = languages.find(lang => lang.value === i18n.language)

  const { me, logout, loggedIn } = useAuthStore()
  const toast = useToast()
  const { theme, urlPath } = usePartners()
  const { ActionType, generateReferrerId } = useReferrals();

  const referrerId = generateReferrerId(ActionType.REFER_FRIEND);
  const referUrl = `${import.meta.env.VITE_BASE_URL}/${i18n.language}/login${referrerId}&partner=${urlPath}&redirect=/${urlPath}`;

  const navigate = useNavigate()
  
  const changeLang = (lang) => {
    const currentPath = window.location.pathname.split('/').slice(2).join('/');
    i18n.changeLanguage(lang);
    const searchParams = location.search;
    localStorage.setItem('i18n_lang', lang);
    navigate(`/${lang}/${currentPath}${searchParams}`, { replace: true });
  }

  const copyReferral = () => {
    navigator.clipboard.writeText(referUrl);

    toast({
        title: t('quests.referral.copy_text'),
        render: () => (
          <Flex
            padding={'10px'}
            borderRadius={'6px'}
            borderWidth={'1px'}
            borderColor={'#25855A'}
            bg={theme.cardBg}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Check color={'#25855A'} />
            <Text
              marginLeft={'10px'}
              fontSize={'16px'}
              fontWeight={'500'}
              color={theme.fontColor}
            >
              {t('quests.referral.copy_text')}
            </Text>
          </Flex>
        ),
        position: 'top',
        duration: 5000,
        isClosable: false,
    });
  }

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={IconButton}
            width={'36px'}
            height={'36px'}
            icon={<Dots color={theme.fontColor} />}
            backgroundColor={theme.cardBg}
            padding={'10px 3px'}
          />
          <MenuList
            padding={'16px'}
            bg={theme.cardBg}
          >
            <MenuItem
              fontSize={'16px'}
              fontWeight={'300'}
              color={theme.fontColor}
              lineHeight={'26px'}
              icon={<Share width={'16px'} height={'16px'} color={theme.fontColor} />}
              bg={'transparent'}
              onClick={copyReferral}
            >
              {t('quests.hero.share')}
            </MenuItem>
            <Accordion
              allowToggle
            >
              <AccordionItem
                borderWidth={'0px'}
              >
                <h2>
                  <AccordionButton
                    paddingLeft={'10px'}
                    color={theme.fontColor}
                  >
                  <Globe color={theme.fontColor} />
                    <Box as='span' marginLeft={'12px'} marginRight={'10px'} color={theme.fontColor}>
                      {activeLanguage?.name}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel
                  pb={4}
                >
                  <Flex>
                    {languages.map((item, index) => (
                      <MenuItem
                        key={index}
                        width={'auto'}
                        onClick={() => {
                          changeLang(item.value)
                        }}
                        fontSize={'16px'}
                        fontWeight={i18n.language == item.value ? 'bold' : '300'}
                        color={theme.fontColor}
                        bg={'transparent'}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Flex>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            {
              loggedIn && (
                <MenuItem
                  fontSize={'16px'}
                  fontWeight={'300'}
                  color={theme.fontColor}
                  lineHeight={'26px'}
                  height={'48px'}
                  marginTop={'24px'}
                  padding={'15.5px 24px'}
                  borderWidth={'1px'}
                  borderRadius={'6px'}
                  borderColor={theme.fontColor}
                  icon={<Logout2 width={'20px'} height={'20px'} color={theme.fontColor} />}
                  bg={'transparent'}
                  onClick={() => logout()}
                >
                  {t('quests.hero.sign_out')}
                </MenuItem>
              )
            }
          </MenuList>
        </>
      )}
    </Menu>
  )
}

export default MobileMenu