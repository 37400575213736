import * as React from 'react'

export const LogoText = ({
  height = '24px',
  width = '24px',
  color = 'white',
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="192"
    height="66"
    viewBox="0 0 192 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.7595 11.0996C19.1696 7.59278 25.4359 3.81288 31.7884 0.191071C38.1337 3.80569 44.3928 7.57841 50.7957 11.0924C54.8774 11.308 58.9807 11.1068 63.0768 11.1787C61.1437 14.8651 58.7148 18.3073 56.9901 22.08C56.868 29.3021 56.9758 36.5313 56.9398 43.7605C50.6232 47.4398 44.1773 50.9395 37.9828 54.8128C35.8198 58.2837 33.8652 61.8983 31.7884 65.4267C30.0422 62.3582 28.2384 59.3257 26.5066 56.2572C26.0467 55.517 25.673 54.6403 24.8394 54.2523C18.7815 50.7455 12.7236 47.2243 6.63695 43.7534C6.62977 36.7756 6.65132 29.7979 6.63695 22.8202C6.74474 21.7782 6.08362 20.923 5.62371 20.0607C3.89904 17.1144 2.16718 14.1681 0.5 11.1787C4.58891 11.114 8.685 11.308 12.7595 11.0996ZM23.2225 11.193C28.9068 11.1571 34.591 11.193 40.2752 11.1787C37.4942 9.4468 34.6125 7.87304 31.7884 6.21304C28.9355 7.88022 26.0539 9.4971 23.2225 11.193ZM9.48984 16.3814C16.9347 29.2374 24.322 42.1221 31.7884 54.9637C39.2548 42.1221 46.6493 29.2374 54.0869 16.3814C39.2188 16.3886 24.3579 16.3886 9.48984 16.3814ZM11.8397 30.883C11.8469 34.1527 11.8613 37.4152 11.8325 40.6849C14.671 42.388 17.5599 44.0049 20.4128 45.6792C17.5096 40.7711 14.7213 35.8055 11.8397 30.883ZM43.1928 45.7008C46.0097 43.9689 48.9201 42.3952 51.7443 40.6849C51.7155 37.4224 51.7299 34.1527 51.7371 30.8902C48.8698 35.8127 46.0313 40.7567 43.1928 45.7008Z"
      fill={color}
    />
    <path
      d="M12.4433 18.0342C20.0391 17.9767 27.642 18.0198 35.2377 18.0127C32.6076 22.5687 29.999 27.1462 27.3258 31.6878C24.9831 31.6447 22.6404 31.6591 20.2978 31.6663C17.6892 27.1175 15.0375 22.5902 12.4433 18.0342ZM21.505 23.0358C22.2452 24.3508 22.9926 25.6659 23.8405 26.9091C24.6813 25.6659 25.4287 24.358 26.1616 23.0501C24.6094 23.0358 23.0572 23.0286 21.505 23.0358Z"
      fill={color}
    />
    <path
      d="M37.0271 17.9983C41.7268 18.027 46.4337 17.9695 51.1334 18.0342C47.9356 23.6609 44.6372 29.2374 41.4465 34.8641C39.3841 34.9072 37.3217 34.9 35.2665 34.8785C36.2078 33.1826 37.2139 31.5082 38.1194 29.7907C37.6954 29.8266 36.8402 29.8985 36.4163 29.9344C34.6988 32.4208 33.4124 35.1731 31.7884 37.717C30.7392 35.9492 29.7044 34.1742 28.7055 32.3777C31.5369 27.6205 34.2676 22.7986 37.0271 17.9983ZM38.7446 25.7808C40.7926 26.3845 41.2884 24.2861 42.0645 22.9351C40.0381 22.4249 39.4919 24.4155 38.7446 25.7808Z"
      fill={color}
    />
    <path
      d="M114.795 23.6394C114.659 22.195 115.981 20.9087 117.425 21.0596C121.608 21.0668 125.79 21.0165 129.972 21.0883C131.611 21.0308 132.394 22.698 132.365 24.1137C132.437 30.9405 132.373 37.7673 132.394 44.5942C130.296 44.5942 128.205 44.5942 126.106 44.5942C126.106 42.6755 126.106 40.7639 126.106 38.8453C124.367 38.8453 122.635 38.8453 120.896 38.8453C120.896 40.7639 120.896 42.6755 120.896 44.5942C118.863 44.5942 116.822 44.5942 114.788 44.5942C114.788 37.6092 114.774 30.6243 114.795 23.6394ZM120.86 26.3342C120.918 28.7631 120.896 31.1992 120.896 33.6353C122.628 33.6353 124.367 33.6353 126.106 33.6353C125.855 31.1417 126.645 28.3391 125.704 26.0324C124.094 25.8168 122.398 25.709 120.86 26.3342Z"
      fill={color}
    />
    <path
      d="M147.528 23.4669C147.542 21.9794 149.052 20.8943 150.474 21.0596C155.282 21.0452 160.097 21.0596 164.911 21.0596C164.911 22.7986 164.911 24.5305 164.911 26.2695C161.196 26.2695 157.488 26.2695 153.773 26.2695C153.787 30.6387 153.73 35.015 153.809 39.3842C155.577 39.3842 157.352 39.3842 159.127 39.3842C159.155 38.0045 159.162 36.6319 159.177 35.2594C158.085 35.2522 157.007 35.2522 155.929 35.2522C155.929 33.5131 155.929 31.7813 155.929 30.0422C159.522 30.0422 163.115 30.0422 166.708 30.0422C166.708 31.7813 166.708 33.5203 166.694 35.2665C166.205 35.2594 165.228 35.2522 164.739 35.245C164.732 37.8392 164.71 40.4262 164.76 43.0204C163.503 45.0756 161.016 44.5726 158.968 44.6013C155.857 44.5366 152.745 44.7019 149.648 44.5366C148.225 44.436 147.356 42.9198 147.492 41.6047C147.499 35.554 147.435 29.5105 147.528 23.4669Z"
      fill={color}
    />
    <path
      d="M72.9361 21.4189C73.9566 20.7793 75.2141 21.1458 76.3495 21.0452C79.9282 21.117 83.5141 20.9661 87.0928 21.1027C88.4007 21.0093 89.3492 22.2668 89.263 23.4885C89.2846 29.7476 89.2846 36.0139 89.2702 42.2802C89.3564 43.4372 88.2713 44.5438 87.1287 44.5438C82.7955 44.6588 78.4623 44.5797 74.1362 44.5869C72.893 44.6157 71.6426 43.4515 71.6929 42.194C71.6211 36.2295 71.7001 30.2578 71.657 24.2933C71.542 23.201 71.8941 21.965 72.9361 21.4189ZM77.9592 26.2695C77.9736 30.6315 77.9377 35.0006 77.9808 39.3626C79.6983 39.3842 81.4158 39.3842 83.1332 39.3842C83.1979 35.0222 83.1548 30.6602 83.162 26.2982C81.4301 26.2623 79.6911 26.2695 77.9592 26.2695Z"
      fill={color}
    />
    <path
      d="M95.9245 44.5941C95.9317 36.7469 95.9173 28.8996 95.9317 21.0524C98.0229 21.0596 100.121 21.0596 102.212 21.0596C102.212 27.1678 102.212 33.276 102.212 39.3842C104.67 39.3842 107.121 39.3842 109.578 39.3842C109.578 41.1232 109.578 42.8551 109.578 44.5941C105.029 44.5941 100.473 44.5941 95.9245 44.5941Z"
      fill={color}
    />
    <path
      d="M172.306 23.5891C172.248 22.2525 173.499 20.9662 174.85 21.0668C179.794 21.0237 184.738 21.0955 189.675 21.038C189.703 22.7843 189.703 24.5233 189.703 26.2695C185.988 26.2695 182.28 26.2695 178.565 26.2695C178.565 30.6387 178.565 35.015 178.565 39.3842C180.347 39.3842 182.136 39.3986 183.926 39.3195C183.947 37.9685 183.955 36.6104 183.962 35.2522C182.877 35.2522 181.799 35.2522 180.721 35.2522C180.721 33.5131 180.721 31.7813 180.721 30.0422C184.314 30.0422 187.907 30.0422 191.5 30.0422C191.5 31.7813 191.5 33.5131 191.5 35.245C191.004 35.2522 190.012 35.2522 189.517 35.2594C189.481 37.832 189.646 40.419 189.43 42.9916C188.927 43.8468 188.123 44.7019 187.037 44.587C182.963 44.6013 178.881 44.6301 174.799 44.5654C173.175 44.666 172.054 43.0204 172.284 41.5041C172.284 35.5324 172.234 29.5608 172.306 23.5891Z"
      fill={color}
    />
    <path
      d="M21.1529 33.1035C23.1938 33.0963 25.2346 33.0963 27.2827 33.0891C28.8133 35.6618 30.2793 38.2632 31.7884 40.8502C32.6723 39.3411 33.549 37.8248 34.4401 36.3157C36.4737 36.3301 38.5146 36.3229 40.5555 36.3445C37.6523 41.425 34.7203 46.4841 31.7884 51.5503C28.2241 45.4062 24.6813 39.2548 21.1529 33.1035Z"
      fill={color}
    />
  </svg>
)

export default LogoText
