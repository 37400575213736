
import { Box, Button, Flex, ModalHeader, Spinner, Text } from "@chakra-ui/react";
import { fetcher, QuestEndpoints } from "@olagg/api-hooks";
import { QUEST_STATUS } from "@olagg/db-types";
import { useQuestsStore } from "@olagg/store";
import { useState } from "react";
import Modal from "../../../components/Modal/Modal";
import { useTranslation } from "react-i18next"

const TwitterTweetModal = () => {
    const lang = localStorage.getItem('i18n_lang') || 'es'
    const { activeQuest: quest, setActiveQuest } = useQuestsStore();
    const isOpen = quest?.trigger === 'user.tweet.tweet' && quest?.status === QUEST_STATUS.PENDING;
    const onClose = () => setActiveQuest(null);
    const { t } = useTranslation()
    const title = lang === 'es' ? quest.title : quest.extraData?.[lang]?.title || quest.title
    const description = lang === 'es' ? quest.description : quest.extraData?.[lang]?.description || quest.description
    const buttonText = lang === 'es' ? quest.buttonText : quest.extraData?.[lang]?.buttonText || quest.buttonText
    const header = <ModalHeader
        textAlign={'center'}
        borderBottom={'1px solid'}
        mx={8}
    >
        {title}
    </ModalHeader>;
    const text = lang === 'es' ? quest?.extraData?.tweetText : quest?.extraData?.[lang]?.tweetText;
    const [verifying, setVerifying] = useState(false);

    const submit = () => fetcher(QuestEndpoints.completeTwitterQuest(quest!.id!))
        .then(() => setActiveQuest({ ...quest!, status: QUEST_STATUS.COMPLETED }))


    const handleClick = () => {
        const url = `https://twitter.com/intent/tweet?text=${text}`
        window.open(url, '_blank').focus();
        setVerifying(true);
        setTimeout(() => {
            submit()
        }, 5000)
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'sm'} modalHeader={header}>
            <Flex justify={'center'} align={'center'} direction={'column'} w={'100%'}>
                <Box m={6} textAlign='center'>
                    <Text fontSize={'xl'}>
                        {t('quests.twitter.write_a_tweet')}
                    </Text>
                    <Text fontSize={'lg'} fontWeight={'bold'} mt={4}>
                        {text}
                    </Text>
                </Box>
                <Flex mt={4} w={'100%'} justify={'center'} direction={'column'}>
                    {!verifying && <Button
                        onClick={handleClick}
                        backgroundColor={'black'}
                        m={4}
                        color={'white'}
                        _hover={{
                            backgroundColor: 'black.600'
                        }}
                    >
                        {buttonText || t('quests.twitter.write_post')}
                    </Button>}
                    {verifying && <Button disabled backgroundColor={'gray.800'} color={'white'} m={4}>
                        <Spinner size='sm' color='white' mr={2} />
                        {t('quests.twitter.validating')}...
                    </Button>}
                </Flex>
            </Flex>
        </Modal>
    );
};

export default TwitterTweetModal;