import { Box, Button, Flex, Input, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Text, useMediaQuery, useToast } from "@chakra-ui/react"
import Check from "@olagg/ui-kit/svg/Check"
import { FaRegCopy } from 'react-icons/fa'
import { ReferFriend } from "@olagg/ui-kit"
import usePartners from '../../hooks/usePartners';
import { useTranslation } from "react-i18next";
import { useReferrals } from "./hooks/useReferrals";
import { AiOutlineInfoCircle } from "react-icons/ai";

const ReferralCard = () => {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')
  const toast = useToast()
  const { theme, mainColor } = usePartners()
  const { t } = useTranslation()
  const { urlPath } = usePartners()
  const { data, ActionType, generateReferrerId } = useReferrals();

  const referrerId = generateReferrerId(ActionType.REFER_FRIEND);
  const url = `${import.meta.env.VITE_BASE_URL}/login${referrerId}&partner=${urlPath}&redirect=/${urlPath}`;
  const relevantActions = data?.relevantActions;
  const score = relevantActions?.find(action => action.id === 1)?.score ?? 0
  

  const copyReferral = () => {
    navigator.clipboard.writeText(url);
    toast({
        title: t('quests.referral.copy_text'),
        render: () => (
          <Flex
            padding={'10px'}
            borderRadius={'6px'}
            borderWidth={'1px'}
            borderColor={'#25855A'}
            bg={theme.cardBg}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Check color={'#25855A'} />
            <Text
              marginLeft={'10px'}
              fontSize={'16px'}
              fontWeight={'500'}
              color={theme.fontColor}
            >
              {t('quests.referral.copy_text')}
            </Text>
          </Flex>
        ),
        position: 'top',
        duration: 5000,
        isClosable: false,
    });
  }

  const PendingScoreInfo = () => {
    return (
      <Popover boundary='scrollParent'>
        <PopoverTrigger>
          <Box zIndex='3' position='relative' mt='-2px'>
            <AiOutlineInfoCircle cursor='pointer' fontSize='1.3rem' color={`${theme.fontColor}77`} />
          </Box>
        </PopoverTrigger>
        <PopoverContent
          bg='#717171' color='white' border='none'
          _focusVisible={{ outline: 'none' }} fontSize='.9rem'
          textAlign='left' fontWeight='400'
        >
          <PopoverArrow bg='#717171' />
          <PopoverBody whiteSpace='break-spaces'>
            <div dangerouslySetInnerHTML={
              { __html: t('quests.referral.pendingScoreInfoPopover', { 'interpolation': { 'escapeValue': false } }) }
            } />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    )
  }

  return (
    <Flex
      w={'full'}
      paddingRight={{base: '0px', md: '0px', lg: '20%', xl: '0px'}}
      paddingLeft={{base: '0px', md: '0px', lg: '20%', xl: '0px'}}
      justifyContent={'center'}
      my={'68px'}
    >
      <Flex
        width={'1100px'}
        height={{base: 'auto', md: 'auto', lg: 'auto', xl: '212px'}}
        borderWidth={'4px'}
        borderColor={theme.stroke}
        borderRadius={'12px'}
        bg={theme.cardBg}
        padding={{base: '24px 16px', md: '14px 36px'}}
        flexDir={{base: 'column', md: 'column', lg: 'column', xl: 'row'}}
      >
        <Flex
          flexDir={'column'}
          textAlign={{base: 'center', md: 'center', lg: 'center', xl: 'start'}}
          justifyContent={'center'}
        >
          <Text
            color={theme.fontColor}
            fontSize={{base: '20px', md: '24px'}}
            fontWeight={'600'}
            lineHeight={{base: '30px', md: '34px'}}
            marginBottom={'8px'}
          >
            {t('quests.referral.title')}
          </Text>
          <Text
            color={theme.fontColor}
            fontSize={{base: '14px', md: '14px', lg: '18px', xl: '16px'}}
            fontWeight={'400'}
            lineHeight={{base: '22px', md: '26px'}}
            marginBottom={'24px'}
          >
            {t('quests.referral.description_1')} <strong>{score} XP</strong> {t('quests.referral.description_2')}
          </Text>
          <Flex
            gap={'12px'}
            flexDir={{base: 'column', md: 'column', lg: 'column', xl: 'row'}}
          >
            <Input
              readOnly
              h='48px'
              borderColor={theme.stroke}
              bg='#FFFFFF'
              value={url}
            />
            <Button
              width={{base: 'full', md: 'full', lg: 'full', xl: '200px'}}
              height={'48px'}
              padding={{base: '10px 16px', md: '10px 16px', lg: '10px 16px', xl: '10px 24px'}}
              bg={mainColor}
              color={theme.referralFontColor}
              fontSize={{base: '16px', md: '18px'}}
              fontWeight={{base: '600', md: '700'}}
              lineHeight={{base: '26px', md: '28px'}}
              borderRadius={'6px'}
              onClick={copyReferral}
              _hover={{boxShadow: `0px 0px 12px 0px ${mainColor}`}}
            >
              <FaRegCopy width={'20px'} height={'20px'} style={{marginRight: '6px'}} color={theme.referralFontColor} size={14} />{t('quests.referral.cta_text')}
            </Button>
          </Flex>
        </Flex>

        <Flex
          alignItems={'center'}
          ml={{base: '0px', md: '0px', lg: '0px', xl: 'auto'}}
          borderLeftWidth={{base: '0px', md: '0px', lg: '0px', xl: '1px'}}
          borderColor={theme.stroke}
          padding={{base: '0px 20px', md: '0px 68px'}}
          flexDir={'column'}
          justifyContent={'center'}
        >
          <Flex alignItems={'center'} mt={'20px'} mb={{base: '8px', md: '16px'}} >
            {
              isLargerThan768 ? (
                <ReferFriend color={theme.fontColor} width='26.5px' height='20px' />
              ) : (
                <ReferFriend color={theme.fontColor} width='19px' height='15px' />
              )
            }
            <Text
              fontSize={{base: '16px', md: '24px'}}
              fontWeight={'600'}
              lineHeight={{base: '26px', md: '34px'}}
              color={theme.fontColor}
              marginLeft={'6px'}
            >
              {t('quests.referral.subtitle')}
            </Text>
          </Flex>
          <Text
            fontSize={{base: '24px', md: '36px'}}
            fontWeight={'700'}
            lineHeight={{base: '34px', md: '46px'}}
            color={theme.fontColor}
          >
            {relevantActions?.find(action => action.id === 1)?.userScore ?? 0}
          </Text>
          {
            relevantActions?.find(action => action.id === 1)?.userPendingScore > 0 && (
            <>
              <Flex alignItems={'center'} mt={'16px'} mb={{base: '8px', md: '10px'}} >
                <PendingScoreInfo />
                <Text
                  fontSize={{base: '14px', md: '14px'}}
                  fontWeight={'600'}
                  lineHeight={{base: '13px', md: '17px'}}
                  color={`${theme.fontColor}77`}
                  marginLeft={'6px'}
                >
                  {t('quests.referral.pendingSubtitle')}
                </Text>
              </Flex>
              <Text
                fontSize={{base: '18px', md: '18px'}}
                fontWeight={'700'} mb='8px'
                lineHeight={{base: '17px', md: '23px'}}
                color={`${theme.fontColor}77`}
              >
                {relevantActions?.find(action => action.id === 1)?.userPendingScore}
              </Text>
            </>)
          }
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ReferralCard
