import { Box, Button, Center, Flex, Image, Input, ModalFooter, ModalHeader, Text, useToast } from "@chakra-ui/react";
import { LoginWithProviderEndpoint, fetcher } from "@olagg/api-hooks"
import { signInWithGoogleProvider, supabase } from "@olagg/plugins"
import { useAuthStore, useQuestsStore } from "@olagg/store";
import { useEffect, useRef, useState } from "react";

import Modal from "../../../components/Modal/Modal";
import { QUEST_STATUS } from "@olagg/db-types";
import Xp from "@olagg/ui-kit/svg/Xp";
import XpStatic from "@olagg/ui-kit/svg/XpStatic";
import { useTranslation } from "react-i18next";

const LinkGoogleAccountModal = () => {
    const { me, getMe } = useAuthStore();
    const { i18n, t } = useTranslation();
    const { activeQuest: quest, setActiveQuest } = useQuestsStore();
    const isOpen = quest?.trigger === 'quest.google.linked' && quest?.status === QUEST_STATUS.PENDING;
    const onClose = () => setActiveQuest(null);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const toast = useToast();
    const [linked, setLinked] = useState<boolean>(false)

    const getUserTokenGoogle = async (firebaseResponse: any) => {
        await fetcher(LoginWithProviderEndpoint.google({
            idToken: firebaseResponse?.credential.idToken,
            accessToken: firebaseResponse?.credential.accessToken,
            provider: 'google'
        }))
    }

    const onSubmit = async () => {
        setIsLoading(true)
        const response = await signInWithGoogleProvider()
        await getUserTokenGoogle(response)
        await getMe()
        if (me?.authProviderData?.google?.id) setLinked(true)
        setIsLoading(false)
    }

    return (
        <Modal onClose={onClose} isOpen={isOpen} >
            {!linked ? (
                <Box p='5'>
                    <Box py='2' borderBottom='1px solid #BEB079'>
                        <Text textAlign='center' fontSize='1.5rem' fontWeight='600'>{t('myAccount.authProviders.linkAccount')}</Text>
                    </Box>
                    <Text py='5' textAlign='center' fontSize='1.25rem'>{
                        quest?.description ?
                            i18n.language === 'es' ? quest?.description : (i18n.language === 'en' ? (quest.extraData.en?.description && quest.extraData.en?.description) || quest?.description : (quest.extraData.pt?.description && quest.extraData.pt?.description) || quest?.description) :
                            i18n.language === 'es' ? quest?.title : (i18n.language === 'en' ? (quest.extraData.en?.title && quest.extraData.en?.title) || quest?.title : (quest.extraData.pt?.title && quest.extraData.pt?.title) || quest?.title)
                    }</Text>
                    <Button
                        w='full'
                        variant='questsDefault'
                        isDisabled={isLoading}
                        isLoading={isLoading}
                        onClick={onSubmit}
                    >
                        {t('myAccount.authProviders.linkAccount')}
                    </Button>
                </Box>
            ) : (
                <Box p='5'>
                    <Box py='2' borderBottom='1px solid #BEB079'>
                        <Text textAlign='center' fontSize='1.5rem' fontWeight='600'>{t('myAccount.authProviders.linkAccount')}</Text>
                    </Box>
                    <Box my='5' py='3' borderRadius='10' bgColor='gray.100'>
                        <Flex justifyContent='center' alignItems='center' gap='1'>
                            <Text fontSize='3.625rem' fontWeight='600'>+{quest.score}</Text>
                            <XpStatic width='65' height='65' color='black' />
                        </Flex>
                        <Text textAlign='center' fontSize='1rem'>{t('quests.congratulations')}</Text>
                    </Box>
                    <Button w='full' variant='questsDefault' onClick={() => onClose()}>{t('quests.back_to_quests')}</Button>
                    <Text mt='5' textAlign='center' fontSize='1rem' color='gray.600'>{t('quests.xp_accumulated')}</Text>
                    <Flex justifyContent='center' alignItems='center' gap='1'>
                        <Text fontSize='1.25rem' fontWeight='500'>{me?.level?.score}</Text>
                        <XpStatic width='25' color='#4A5568' />
                    </Flex>
                </Box>
            )}
        </Modal>
    )
};

export default LinkGoogleAccountModal;