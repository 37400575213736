import { Box, Flex, Text } from '@chakra-ui/react';

import Modal from "../../../components/Modal/Modal";
import Roulette from "../components/Roulette";
import { RouletteValue } from "@olagg/db-types";
import { SpinButton } from "../components/Roulette/SpinButton";
import { useTranslation } from "react-i18next";

export default function RouletteModal({
  onClose,
  isOpen,
  openResults,
}: {
  onClose: () => void,
  isOpen: boolean,
  openResults: () => void,
}) {
  const {t} = useTranslation()
  const showResults = (result: RouletteValue) => {
    openResults();
    onClose();
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <Box p='5'>
        <Box py='2' borderBottom='1px solid #BEB079'>
          <Text textAlign='center' fontSize='1.5rem' fontWeight='600'>{t('quests.daily_spin.roulette.title')}</Text>
        </Box>
        <Text pt='5' textAlign='center' fontSize='1.25rem'>{t('quests.daily_spin.roulette.subtitle')}</Text>
        <Flex w='full' justifyContent='center'> 
          <Box>
            <Roulette />
          </Box>
        </Flex>
        <SpinButton callback={showResults} />
      </Box>
    </Modal>
  )
}
