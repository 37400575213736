import { Flex, } from '@chakra-ui/react'
import { FC, useEffect } from 'react'
import { useOnboardingStore } from '@olagg/store'

interface IProps {
  onClickLogin?: (
    provider: 'google' | 'discord' | 'facebook'
  ) => void
  onLoad?: () => void
  referrerId?: string
  redirect?: string
}

const Login: FC<IProps> = ({
  onClickLogin,
  onLoad,
  referrerId
}) => {
  const { openOnboarding } = useOnboardingStore()
  useEffect(() => {
    if (!localStorage.getItem('visited')) {
      localStorage.setItem('visited', new Date().toString())
      if (onLoad) {
        onLoad()
      }
    }

    const t = setTimeout(() => {
      if (window.location.href.includes('/login')) {
        if (onLoad) {
          onLoad()
        }
      }
    }, 50)

    openOnboarding()

    return () => {
      clearTimeout(t)
    }
  }, [])

  return (
    <Flex
      w="100vw"
      h="100vh"
      maxWidth="1100px"
      margin="0 auto"
      align="center"
    >

    </Flex>
  )
}

export { Login }
export default Login
