import {Flex, Image, Spinner, Text } from "@chakra-ui/react"

import Menu from "./Menu";
import OlaggMarkdown from "@olagg/ui-kit/OlaggMarkdown";
import User from "./User";
import useMetaTags from "react-metatags-hook";
import usePartners from "../../hooks/usePartners";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const { theme, logo, instructions, extraData, title, urlPath } = usePartners()
  const { i18n, t } = useTranslation()

  const removeHtmlTags = (input) => {
    return input ? input.replace(/<[^>]*>/g, '') : '';
  }

  const getMetaTitle = () => {
    let data = title
    if (extraData && extraData.hasOwnProperty(i18n.language) && extraData[i18n.language]?.metas?.title)
      data = extraData[i18n.language].metas?.title

    return data
  }

  const getMetaDescription = () => {
    let data = removeHtmlTags(instructions)
    if (extraData && extraData.hasOwnProperty(i18n.language) && extraData[i18n.language]?.metas?.description)
      data = extraData[i18n.language].metas?.description

    return data
  }

  const getMetaImage = () => {
    let data = logo
    if (extraData && extraData.hasOwnProperty(i18n.language) && extraData[i18n.language]?.metas?.image)
      data = extraData[i18n.language].metas?.image

    return data
  }

  const metas = {
    title: getMetaTitle(),
    description: getMetaDescription(),
    url: `${import.meta.env.VITE_BASE_URL}/${urlPath}`,
    image: getMetaImage(),
    author: '@olagg'
  }

  useMetaTags({
    title: metas.title,
    description: metas.description,
    charset: 'utf8',
    lang: 'es',
    metas: [
      { name: 'twitter:title', content: metas.title },
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:url', content: metas.url },
      { name: 'twitter:description', content: metas.description },
      { name: 'twitter:image', content: metas.image },
      { name: 'twitter:creator', content: metas.author },
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: metas.title },
      { property: 'og:description', content: metas.description },
      { property: 'og:site_name', content: 'Ola GG' },
      { property: 'og:url', content: metas.url },
      { property: 'og:image', content: metas.image },
      { property: 'og:image', content: metas.image },
      { property: 'fb:app_id', content: `${import.meta.env.VITE_FACEBOOK_CLIENT_ID}` },
      { p: 'og:image', c: metas.image },
    ],
  }, [metas])

  return (
    <Flex
      w={'full'}
      flexDir={'column'}
      position={'relative'}
    >
        <Flex
          bg={theme.footerVisit}
          width={'100%'}
          paddingLeft={{ base: '24px', md: '24px', lg: '100px' }}
          paddingRight={{ base: '24px', md: '24px', lg: '100px' }}
          paddingTop={'60px'}
          paddingBottom={'70px'}
          flexDirection={'column'}
          backgroundImage={extraData?.bgImage ? `url(${extraData.bgImage})` : 'none'}
          backgroundSize="cover"
          backgroundPosition="center"
        >
          <Flex
            width={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {logo ? (
              <Image
                src={logo}
                maxHeight={{ base: '68px', xl: '120px' }}
                objectFit={'contain'}
              />
            ) : (
              <Spinner />
            )}
            <Menu />
          </Flex>
          <Text
            fontSize={{ base: '28px', md: '32px', lg: '32px', xl: '32px' }}
            fontWeight={'700'}
            color={theme.fontColor}
            textAlign={'center'}
            marginTop={'24px'}
            marginBottom={'12px'}
          >
            {i18n.language === 'es' ? title : (i18n.language === 'en' ? (extraData?.en?.title ?? title) : (extraData?.pt?.title ?? title))}
          </Text>
          <OlaggMarkdown color={theme.headerFontColor} textAlign='center'>
            {i18n.language === 'es' ? instructions : (i18n.language === 'en' ? (extraData?.en?.instructions ?? instructions) : (extraData?.pt?.instructions ?? instructions))}
          </OlaggMarkdown>
        </Flex>
      <User />
    </Flex>
  )
}

export default Hero