import { Box, Button, Flex, ModalHeader, Spinner } from "@chakra-ui/react";
import { fetcher, QuestEndpoints } from "@olagg/api-hooks";
import { QUEST_STATUS } from "@olagg/db-types";
import { useQuestsStore } from "@olagg/store";
import { useState } from "react";
import Modal from "../../../components/Modal/Modal";
import TwitterTweetEmbed from "../components/Twitter/TwitterTweetEmbed";
import { useTranslation } from "react-i18next"
import { isMobile } from "@olagg/ui-kit";

const TwitterRetweetModal = () => {
    const lang = localStorage.getItem('i18n_lang') || 'es'
    const { activeQuest: quest, setActiveQuest } = useQuestsStore();
    const isOpen = quest?.trigger === 'user.tweet.retweet' && quest?.status === QUEST_STATUS.PENDING;
    const onClose = () => setActiveQuest(null);
    const { t } = useTranslation()
    const title = lang === 'es' ? quest.title : quest.extraData?.[lang]?.title || quest.title

    const header = <ModalHeader
        textAlign={'center'}
        borderBottom={'1px solid'}
        mx={8}
    >
        {title}
    </ModalHeader>;
    const tweetId = quest?.extraData?.tweetId;
    const [verifying, setVerifying] = useState(false);

    const onLoad = (event = null) => () => {
        if (event) window.twttr.events.bind(event, () => handleClick());
    }

    const submit = () => fetcher(QuestEndpoints.completeTwitterQuest(quest.id))
        .then(() => setActiveQuest({ ...quest, status: QUEST_STATUS.COMPLETED }))

    const handleClick = () => {
        let url;
        if (isMobile(window)) {
            url = `twitter://status?id=${tweetId}`
        } else {
            url = `https://twitter.com/intent/retweet?tweet_id=${tweetId}`
        }
        window.open(url, '_blank').focus();
        setVerifying(true);
        setTimeout(() => {
            submit()
        }, 5000)
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'sm'} modalHeader={header}>
            <Flex justify={'center'} align={'center'} direction={'column'} w={'100%'}>
                <TwitterTweetEmbed tweetId={tweetId} onLoad={onLoad} options={{
                    cards: 'hidden',
                    hide_thread: true,
                    'data-conversation': 'none',
                }} />
                <Flex mt={4} w={'100%'} justify={'center'} direction={'column'}>
                    {!verifying && <Button
                        onClick={handleClick}
                        backgroundColor={'black'}
                        m={4}
                        color={'white'}
                        _hover={{
                            backgroundColor: 'black.600'
                        }}
                    >
                        Retweet
                    </Button>}
                    {verifying && <Button disabled backgroundColor={'gray.800'} color={'white'} m={4}>
                        <Spinner size='sm' color='white' mr={2} />
                        {t('quests.twitter.validating')}...
                    </Button>}
                </Flex>
            </Flex>
        </Modal>
    );
};

export default TwitterRetweetModal;