export const Close = ({
  height = '16px',
  width = '16px',
  color = 'white',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12 13" fill="none" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M0.343133 0.943121C-0.0473908 1.33365 -0.0473908 1.96681 0.343133 2.35733L4.58577 6.59998L0.343133 10.8426C-0.0473908 11.2331 -0.0473908 11.8663 0.343133 12.2568C0.733658 12.6474 1.36682 12.6474 1.75735 12.2568L5.99999 8.01419L10.2426 12.2568C10.6332 12.6474 11.2663 12.6474 11.6568 12.2568C12.0474 11.8663 12.0474 11.2331 11.6568 10.8426L7.4142 6.59998L11.6568 2.35733C12.0474 1.96681 12.0474 1.33365 11.6568 0.943121C11.2663 0.552597 10.6332 0.552597 10.2426 0.943121L5.99999 5.18576L1.75735 0.943121C1.36682 0.552597 0.733658 0.552597 0.343133 0.943121Z" fill={color}/>
    </svg>
  )
}
export default Close