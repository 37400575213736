import TwitterFollowModal from "./modals/TwitterFollowModal";
import TwitterLikeModal from "./modals/TwitterLikeModal";
import TwitterRetweetModal from "./modals/TwitterRetweetModal";
import UpdateUserAvatarModal from "./modals/UpdateUserAvatarModal";
import UpdateUsernameModal from "./modals/UpdateUsernameModal";
import LinkDiscordAccountModal from "./modals/LinkDiscordAccountModal";
import LinkGoogleAccountModal from "./modals/LinkGoogleAccountModal";
import LinkXAccountModal from "./modals/LinkXAccountModal";
import LinkWalletModal from "./modals/LinkWalletModal";
import TwitterTweetModal from "./modals/TwitterTweetModal";

const TRIGGERS = {
    'user.avatar.updated': UpdateUserAvatarModal,
    'user.name.set': UpdateUsernameModal,
    'quest.discord.linked': LinkDiscordAccountModal,
    "quest.google.linked": LinkGoogleAccountModal,
    "quest.twitter.linked": LinkXAccountModal,
    "quest.wallet.linked": LinkWalletModal,
    'user.tweet.retweet': TwitterRetweetModal,
    'user.tweet.follow': TwitterFollowModal,
    'user.tweet.like': TwitterLikeModal,
    'user.tweet.tweet': TwitterTweetModal
}

export default TRIGGERS;