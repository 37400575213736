import { Box, Center, Flex, Spinner } from '@chakra-ui/react'

import DailySpinCard from './components/DailySpin'
import Hero from "./components/Hero"
import Quests from './components/Quests'
import ReferralCard from './components/Referral'
import TokenProgressBar from './components/TokenProgressBar'
import Visit from './components/Visit'
import { analytics } from '../../analytics'
import { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import usePartners from './hooks/usePartners'
import { AdTrackingContext } from '../../contexts/adTrackingContext'

const Quest = () => {
  const { fcb, fbp } = useContext(AdTrackingContext)
  const location = useLocation();
  const pathname = location.pathname;
  const pathnameExtract = pathname.split(`/`)[2]

  const { urlPath, getBySlug, isLoading, isPartner } = usePartners()

  useEffect(() => {
    getBySlug(pathnameExtract)
    let props = {
      user_agent: navigator.userAgent,
      owner: pathnameExtract
    }
    if (fcb != null) props.fbc = fcb
    if (fbp != null) props.fbp = fbp
    analytics()?.page("Quest Landing", props)

  }, [location, pathnameExtract])

  useEffect(() => {
    if (!isPartner) {
      window.location.href = `${import.meta.env.VITE_BASE_URL}/404.html`
    }
  }, [isPartner])

  if (isLoading) return (
    <Center w='100%' h={'1000px'} color='white'>
      <Flex w='100%' h={'1000px'} justify='center' align='center' color='white'>
        <Spinner color={'#000'} size="lg" />
      </Flex>
    </Center>
  )

  return (
    <>
      {urlPath === 'olagg' && <TokenProgressBar />}
      <Hero />
      <Box px={'16px'}>
        <DailySpinCard />
        <ReferralCard />
        <Quests />
      </Box>
      <Visit />
    </>
  )
}

export default Quest