import { Box, Button, Flex, Heading, ModalHeader, Text } from '@chakra-ui/react';
import { useAuthStore, useQuestsStore } from "@olagg/store";

import Modal from '../../../components/Modal/Modal';
import { QUEST_STATUS } from '@olagg/db-types';
import XpStatic from '@olagg/ui-kit/svg/XpStatic';
import { useEffect } from 'react';
import useQuest from '../hooks/useQuest'
import { useTranslation } from 'react-i18next';

type QuestCompletedModalProps = {
    reloadQuests: () => void;
};

const QuestCompletedModal = ({ reloadQuests }: QuestCompletedModalProps) => {
    const { me } = useAuthStore();
    const { activeQuest: quest, setActiveQuest } = useQuestsStore();
    const { claimQuest } = useQuest();
    const isOpen = quest?.status === QUEST_STATUS.COMPLETED;
    const { t } = useTranslation();
    const onClose = () => {
        reloadQuests();
        setActiveQuest(null);
    }
    const backToQuests = () => onClose();

    const heading = <ModalHeader
        textAlign={'center'}
        borderBottom={'1px solid'}
        mx={8}
    >
        {quest?.title}
    </ModalHeader>;

    useEffect(() => {
        if (isOpen) {
            claimQuest(quest);
        }
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={backToQuests} size={'sm'} modalHeader={heading}>
            <Box m={8}>
                <Box backgroundColor={'gray.100'} p={6} w={'100%'} borderRadius={'lg'} >
                    <Heading
                        fontSize={'54px'}
                        color={'black'}
                        pb={4}
                        textAlign={'center'}
                        display={'flex'}
                        justifyContent={'center'}
                    >
                        + {quest?.score}
                        <Box mt={3}>
                            <XpStatic width='60' height='40' color='#000' />
                        </Box>
                    </Heading>
                    <Box textAlign={'center'} fontWeight={'semibold'} fontSize={'sm'}>
                        {t('quests.congratulations')}
                    </Box>
                </Box>

                <Button
                    mt={6}
                    w='full'
                    variant='questsDefault'
                    onClick={backToQuests}
                >
                    {t('quests.back_to_quests')}
                </Button>
                <Text mt='5' textAlign='center' fontSize='1rem' color='gray.600'>{t('quests.xp_accumulated')}</Text>
                <Flex justifyContent='center' alignItems='center' gap='1'>
                    <Text fontSize='1.25rem' fontWeight='500'>{me?.level?.score}</Text>
                    <XpStatic width='25' color='#4A5568' />
                </Flex>
            </Box>
        </Modal >
    );
};

export default QuestCompletedModal;
