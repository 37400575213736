import { PartnersEndpoints, fetcher } from "@olagg/api-hooks";
import { createContext, useContext, useState } from "react";

const lightMode = {
  background: '#F3F3F8',
  cardBg: '#FFFFFF',
  questsBtn: '#000000',
  btnTextColor: '#FFFFFF',
  rouletteFontColor: '#FFFFFF',
  referralFontColor: '#FFFFFF',
  questBtnFontColor: '#FFFFFF',
  stroke: '#CBD5E0',
  fontColor: '#000000',
  headerFontColor: '#000000',
  footerFontColor: '#FFFFFF',
  footerVisit: '#CBD5E0',
  footerVisitFontColor: '#000000',
  footer: '#1F1D2A',
};

const darkMode = {
  background: '#1F1D2A',
  cardBg: '#262835',
  questsBtn: '#FFFFFF',
  btnTextColor: '#000000',
  rouletteFontColor: '#FFFFFF',
  referralFontColor: '#FFFFFF',
  questBtnFontColor: '#000000',
  stroke: '#494A55',
  fontColor: '#FFFFFF',
  headerFontColor: '#FFFFFF',
  footerFontColor: '#FFFFFF',
  footerVisit: '#43414C',
  footerVisitFontColor: '#000000',
  footer: '#2F3141',
};

const olaGGTheme = {
  background: '#14053D',
  cardBg: '#110434',
  questsBtn: 'linear-gradient(90deg, #4E10FF 0%, #AB17DF 100%)',
  btnTextColor: '#000000',
  rouletteFontColor: '#000000',
  referralFontColor: '#FFFFFF',
  questBtnFontColor: '#FFFFFF',
  stroke: '#4A5568',
  fontColor: '#FFFFFF',
  headerFontColor: '#FFFFFF',
  footerFontColor: '#FFFFFF',
  footerVisit: '#3A2D5C',
  footerVisitFontColor: '#FFFFFF',
  footer: '#44337A',
}

const PartnersContext = createContext({
  theme: lightMode,
  essentialQuests: null,
  mainColor: null,
  isLoading: false,
  getBySlug: (slug: string) => {},
  instructions: null,
  title: null,
  name: null,
  extraData: null,
  logo: null,
  error: false,
  ownerId: null,
  isOlaGG: false,
  urlPath: '',
  isPartner: true
})

export default function usePartners() {
  return useContext(PartnersContext)
}

export function PartnersProvider({children}) {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [essentialQuests, setEssentialQuests] = useState(null)
  const [theme, setTheme] = useState(lightMode)
  const [mainColor, setMainColor] = useState(null)
  const [instructions, setInstructions] = useState(null)
  const [title, setTitle] = useState(null)
  const [name, setName] = useState(null)
  const [extraData, setExtraData] = useState(null)
  const [logo, setLogo] = useState(null)
  const [error, setError] = useState<boolean>(false)
  const [ownerId, setOwnerId] = useState(null)
  const [isOlaGG, setIsOlaGG] = useState<boolean>(false)
  const [urlPath, setUrlPath] = useState<string>(null)
  const [isPartner, setIsPartner] = useState<boolean>(true)

  const getBySlug = (slug: string) => {
    setIsLoading(true)
    fetcher(PartnersEndpoints.by_slug(slug))
      .then((res) => {
        if (!res) {
          setError(true)
          setIsPartner(false)
        } else {
          if (res.owner.urlPath == 'olagg') {
            setIsOlaGG(true)
            setTheme(olaGGTheme)
            setMainColor(res.owner.mainColor)
          } else {
            setIsOlaGG(false)
            setTheme(res.owner.darkMode ? darkMode : lightMode)
            setMainColor(res.owner.mainColor)
          }
          setIsPartner(res.owner.isPartner)
          setUrlPath(res.owner.urlPath)
          setEssentialQuests(res.owner.essentialQuests?.quests)
          setInstructions(res.owner.instructions)
          setTitle(res.owner.title)
          setName(res.owner.name)
          setExtraData(res.owner.extraData)
          setLogo(res.owner.logoUrl)
          setOwnerId(res.owner.id)
        }
      }).finally(() => setIsLoading(false))
  }

  return (
    <PartnersContext.Provider
      value={{
        theme,
        essentialQuests,
        mainColor,
        isLoading,
        getBySlug,
        instructions,
        title,
        name,
        extraData,
        logo,
        error,
        ownerId,
        isOlaGG,
        urlPath,
        isPartner
      }}
    >
      {children}
    </PartnersContext.Provider>
  )
}