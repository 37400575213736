import { Box, Button, Center, Image, Input, ModalHeader, useToast, Text, ModalFooter } from "@chakra-ui/react";
import { useAuthStore, useQuestsStore } from "@olagg/store";
import { useEffect, useRef, useState } from "react";
import Modal from "../../../components/Modal/Modal";
import avatar from '@olagg/ui-kit/svg/avatar_circle.svg'
import { useTranslation } from "react-i18next";
import { fetcher, UserEndpoints } from "@olagg/api-hooks";
import { QUEST_STATUS } from "@olagg/db-types";

const UpdateUserAvatarModal = () => {
    const { me: user, getMe } = useAuthStore();
    const { activeQuest: quest, setActiveQuest } = useQuestsStore();
    const isOpen = quest?.trigger === 'user.avatar.updated' && quest?.status == QUEST_STATUS.PENDING;
    const onClose = () => setActiveQuest(null);
    const header = <ModalHeader>{quest.title}</ModalHeader>;
    const inputName = useRef<HTMLInputElement | null>(null)
    const [currentUserAvatar, setCurrentUserAvatar] = useState(avatar)
    const [imgPreview, setImgPreview] = useState<string | ArrayBuffer | null>(null)
    const inputFile = useRef<HTMLInputElement | null>(null)
    const { t } = useTranslation();
    const toast = useToast();

    const handleChange = (e: any) => {
        const selectedFile = e.target.files[0]
        if (selectedFile.size > 5000000) {
            toast({
                title: t('myAccount.profile.editModal.error.bigImage'),
                description: t('myAccount.profile.editModal.error.bigImageDescription'),
                status: 'error',
                position: 'bottom-right',
                duration: 2000,
                isClosable: true
            })
            return
        }

        const reader = new FileReader()
        reader?.addEventListener('load', () => {
            setImgPreview(reader.result)
        })
        reader?.readAsDataURL(selectedFile)
    }

    useEffect(() => {
        if (user?.avatar) {
            setCurrentUserAvatar(user.avatar)
        } else {
            setCurrentUserAvatar(avatar)
        }
    }, [user])

    const onSubmit = () => {
        const formData = new FormData()
        if (inputFile.current?.files.length > 0) formData.append('user[avatar]', inputFile.current?.files[0])

        fetcher(UserEndpoints.updateAvatar(user.id, formData))
            .then(async () => {
                toast({
                    title: t('myAccount.profile.editModal.success'),
                    colorScheme: 'olaggPink',
                    status: 'success',
                    position: 'bottom-right',
                    duration: 2000,
                    isClosable: true
                })
                setActiveQuest({ ...quest, status: QUEST_STATUS.COMPLETED })
                await getMe()
            })
            .catch(() =>
                toast({
                    title: t('myAccount.profile.editModal.error.tryAgain'),
                    status: 'error',
                    position: 'bottom-right',
                    duration: 2000,
                    isClosable: true
                })
            )
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'sm'} modalHeader={header}>
            <Box p={6}>
                <Text fontSize="16px" fontWeight="600" color="gray.500" textAlign={'center'} pb={4}>
                    {quest.description}
                </Text>
                <Center position="relative">
                    {!imgPreview && (
                        <Box border="2px solid purple" borderRadius='50%' backgroundColor="purple">
                            <Image
                                w="100px"
                                h="100px"
                                fallbackSrc={avatar}
                                src={currentUserAvatar}
                                objectFit={'cover'} loading='lazy' borderRadius='50%'
                            />
                        </Box>
                    )}
                    {typeof imgPreview === 'string' && (
                        <Box border="2px solid purple" borderRadius='50%' backgroundColor="purple">
                            <Image
                                w="100px"
                                h="100px"
                                fallbackSrc={avatar}
                                src={imgPreview}
                                objectFit={'cover'} loading='lazy' borderRadius='50%'
                            />
                        </Box>
                    )}
                </Center>
                <Center flexDirection="column" columnGap={15} p={5}>
                    <Button
                        variant='link'
                        color='#4E10FF'
                        fontSize="16px"
                        fontWeight='600'
                        onClick={() => inputFile.current?.click()}
                    >
                        {!imgPreview ? t('myAccount.profile.editModal.editProfilePicture') : t('myAccount.profile.editModal.takeAnotherPicture')}
                    </Button>
                </Center>
                <Input
                    display="none"
                    type="file"
                    accept=".png,.jpg,.gif,.jpeg"
                    ref={inputFile}
                    id="file"
                    onChange={handleChange}
                />
            </Box>
            <ModalFooter>
                <Button
                    p={'0 10px'}
                    w="full"
                    variant="filledGradient"
                    fontSize="16px"
                    fontWeight="600"
                    cursor={'pointer'}
                    onClick={onSubmit}
                >
                    {t('myAccount.profile.editModal.saveChanges')}
                </Button>
            </ModalFooter>
        </Modal>
    )
};

export default UpdateUserAvatarModal;