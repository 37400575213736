import { Button, Flex, Image, Text, useDisclosure } from "@chakra-ui/react"
import { useEffect, useLayoutEffect, useRef, useState } from "react"

import ModalSecretWord from "../../../Quests/components/ModalSecretWord"
import { ROUTES } from '../../../../routes'
import ResultsModal from "../../modals/ResultsModal"
import RouletteModal from '../../modals/RouletteModal'
import { RouletteProvider } from "../Roulette/useRoulette"
import image from './assets/daily-spin.png'
import moment from "moment"
import { useAuthStore } from "@olagg/store"
import usePartners from '../../hooks/usePartners';
import { useTranslation } from "react-i18next"

const DailySpinCard = () => {
  const { me, getMe } = useAuthStore();
  const { onOpen: onOpenRoulette, isOpen: isOpenRoulette, onClose: onCloseRoulette } = useDisclosure();
  const { onOpen: onOpenResults, isOpen: isOpenResults, onClose: onCloseResults } = useDisclosure();
  const layoutRef = useRef(null)
  const [layoutWidth, setLayoutWidth] = useState(1366)
  const [rouletteCountdown, setRouletteCountdown] = useState<string>(null);
  const {theme, mainColor} = usePartners()
  const {t} = useTranslation()

  useLayoutEffect(() => {
      setLayoutWidth(layoutRef?.current?.clientWidth)
  }, []);

  useEffect(() => {
      function handleWindowResize() {
          setLayoutWidth(layoutRef?.current?.clientWidth);
      }
      window.addEventListener('resize', handleWindowResize);
      return () => {
          window.removeEventListener('resize', handleWindowResize);
      };
  }, [])

  useEffect(() => {
    getMe();
  }, [isOpenRoulette, isOpenResults]);

  useEffect(() => {
    const timer = !me.canSpinRoulette && setInterval(() => {
      updateRouletteCountdown();
    }, 1000);

    return () => clearInterval(timer);
  }, [me]);

  const updateRouletteCountdown = () => {
    const oneDayAgo = moment.utc().subtract(1, 'days').subtract(1, 'minute'); // plus 1 minute so the backend data is ready
    const lastSpin = moment.utc(me?.lastSpin);
    const countdown = moment.utc(lastSpin.diff(oneDayAgo));
    const formattedCountdown = countdown.get('date') > 1 ? '24hs' : countdown.format('HH:mm:ss'); // show '24hs' when delta is greater than a day
    if (oneDayAgo.isAfter(lastSpin)) {
      getMe();
      setRouletteCountdown(null);
      return;
    }
    setRouletteCountdown(formattedCountdown);
  }

  return (
    <>
      <Flex
        w={'full'}
        paddingRight={{base: '0px', md: '0px', lg: '20%', xl: '0px'}}
        paddingLeft={{base: '0px', md: '0px', lg: '20%', xl: '0px'}}
        ref={layoutRef}
        position={'relative'}
        marginTop={{base: '10px', xl: '80px'}}
        justifyContent={'center'}
      >
        <Flex
          flexDir={{base: 'column', md: 'column', lg: 'column', xl: 'row'}}
          width={'1100px'}
          height={{base: 'auto', md: 'auto', lg:'auto', xl: '212px'}}
          borderWidth={{base: '2px', md: '4px'}}
          borderColor={mainColor}
          borderRadius={'12px'}
          bg={theme.cardBg}
        >
          <Flex
            flexDir={'column'}
            textAlign={{base: 'center', md: 'center', lg: 'center', xl: 'start'}}
            padding={'20px'}
          >
            <Text
              color={theme.fontColor}
              fontSize={{base: '20px', md: '24px'}}
              fontWeight={'600'}
              lineHeight={{base: '30px', md: '34px'}}
              marginBottom={'8px'}
            >
              {t('quests.daily_spin.title')}
            </Text>
            <Text
              color={theme.fontColor}
              fontSize={{base: '16px', md: '18px'}}
              fontWeight={'400'}
              lineHeight={'26px'}
              marginBottom={'24px'}
            >
              {t('quests.daily_spin.description')}
            </Text>
            <Button
              width={{base: '100%', md: '100%', lg: '100%', xl: '50%'}}
              height={'48px'}
              padding={'10px 24px'}
              bg={mainColor}
              color={theme.rouletteFontColor}
              fontSize={'18px'}
              fontWeight={'700'}
              lineHeight={'28px'}
              borderRadius={'6px'}
              _hover={!me.canSpinRoulette ? {} : {boxShadow: `0px 0px 12px 0px ${mainColor}`}}
              disabled={!me.canSpinRoulette}
              onClick={() => onOpenRoulette()}
            >
              {rouletteCountdown ? `${t('quests.daily_spin.disabled_cta_text')} ${rouletteCountdown}` : t('quests.daily_spin.cta_text')}
            </Button>
          </Flex>
          <Flex
            justifyContent={'center'}
            w={{base: '100%', md: '100%', lg: '100%', xl: '50%'}}
          >
            <Image
              src={image}
              width={{base: '297px', md: '381px', lg: '381px', xl: '438px'}}
              height={{base: '187px', md: '240px', lg: '240px', xl: '276px'}}
              position={'relative'}
              bottom={{base: '0%', md: '0%', lg: '0%', xl: '34%'}}
            />
          </Flex>
        </Flex>
      </Flex>
      <RouletteProvider>
        <RouletteModal isOpen={isOpenRoulette} onClose={onCloseRoulette} openResults={onOpenResults} />
        <ResultsModal isOpen={isOpenResults} onClose={onCloseResults} />
      </RouletteProvider>
    </>
  )
}

export default DailySpinCard