export const Dots = ({
  height = '4px',
  width = '14px',
  color = 'white',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 4" fill="none">
      <path d="M3.11111 1.99989C3.11111 2.41245 2.94722 2.80811 2.6555 3.09984C2.36378 3.39156 1.96811 3.55545 1.55556 3.55545C1.143 3.55545 0.747335 3.39156 0.455612 3.09984C0.163888 2.80811 0 2.41245 0 1.99989C0 1.58733 0.163888 1.19167 0.455612 0.899948C0.747335 0.608225 1.143 0.444336 1.55556 0.444336C1.96811 0.444336 2.36378 0.608225 2.6555 0.899948C2.94722 1.19167 3.11111 1.58733 3.11111 1.99989ZM8.55556 1.99989C8.55556 2.41245 8.39167 2.80811 8.09994 3.09984C7.80822 3.39156 7.41256 3.55545 7 3.55545C6.58744 3.55545 6.19178 3.39156 5.90006 3.09984C5.60833 2.80811 5.44444 2.41245 5.44444 1.99989C5.44444 1.58733 5.60833 1.19167 5.90006 0.899948C6.19178 0.608225 6.58744 0.444336 7 0.444336C7.41256 0.444336 7.80822 0.608225 8.09994 0.899948C8.39167 1.19167 8.55556 1.58733 8.55556 1.99989ZM14 1.99989C14 2.41245 13.8361 2.80811 13.5444 3.09984C13.2527 3.39156 12.857 3.55545 12.4444 3.55545C12.0319 3.55545 11.6362 3.39156 11.3445 3.09984C11.0528 2.80811 10.8889 2.41245 10.8889 1.99989C10.8889 1.58733 11.0528 1.19167 11.3445 0.899948C11.6362 0.608225 12.0319 0.444336 12.4444 0.444336C12.857 0.444336 13.2527 0.608225 13.5444 0.899948C13.8361 1.19167 14 1.58733 14 1.99989Z" fill={color}/>
    </svg>
  )
}

export default Dots
