import { Box, Button, Flex, Text } from '@chakra-ui/react';

import Modal from "../../../components/Modal/Modal";
import Xp from "@olagg/ui-kit/svg/Xp";
import XpStatic from "@olagg/ui-kit/svg/XpStatic";
import { useAuthStore } from "@olagg/store"
import useRoulette from '../../Quest/components/Roulette/useRoulette';

export default function ResultsModal({
  onClose,
  isOpen,
}: {
  onClose: () => void,
  isOpen: boolean,
}) {
  const { result } = useRoulette();
  const { me } = useAuthStore();

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <Box p='5'>
        <Box py='2' borderBottom='1px solid #BEB079'>
          <Text textAlign='center' fontSize='1.5rem' fontWeight='600'>Giro diario</Text>
        </Box>
        <Box my='5' py='3' borderRadius='10' bgColor='gray.100'>
          <Flex justifyContent='center' alignItems='center' gap='1'>
            <Text fontSize='3.625rem' fontWeight='600'>+{result?.value}</Text>
            <XpStatic width='65' height='65' color='black' />
          </Flex>
          <Text textAlign='center' fontSize='1rem'>¡Felicitaciones!</Text>
        </Box>
        <Button w='full' variant='questsDefault' onClick={() => onClose()}>Volver a misiones</Button>
        <Text mt='5' textAlign='center' fontSize='1rem' color='gray.600'>Puntos totales acumulados</Text>
        <Flex justifyContent='center' alignItems='center' gap='1'>
          <Text fontSize='1.25rem' fontWeight='500'>{me?.level?.score}</Text>
          <XpStatic width='25' color='#4A5568' />
        </Flex>
      </Box>
    </Modal>
  )
}
