import { Button, Center, Flex, Image, Spinner, Text } from '@chakra-ui/react'
import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react"

import Cookies from "js-cookie";
import DropdownMenu from '../Hero/DropdownMenu'
import { LogoText } from '@olagg/ui-kit'
import OlaggMarkdown from '@olagg/ui-kit/OlaggMarkdown'
import { analytics } from '../../../../analytics'
import { useLocation, useNavigate } from 'react-router-dom'
import useMetaTags from 'react-metatags-hook'
import { useOnboardingStore } from "@olagg/store"
import usePartners from '../../hooks/usePartners'
import { useTranslation } from 'react-i18next'
import { AdTrackingContext } from '../../../../contexts/adTrackingContext';

const PartnersLanding = () => {
  const { fcb, fbp } = useContext(AdTrackingContext)
  const location = useLocation();
  const pathname = location.pathname;
  const pathnameExtract = pathname.split(`/`)[2]

  const { getBySlug, isLoading, theme, instructions, title, mainColor, extraData, logo, isPartner } = usePartners()

  useEffect(() => {
    getBySlug(pathnameExtract)
  }, [location, pathnameExtract])

  const { i18n, t } = useTranslation()

  const layoutRef = useRef(null)
  const { openOnboarding } = useOnboardingStore()

  const removeHtmlTags = (input) => {
    return input ? input.replace(/<[^>]*>/g, '') : '';
  }

  const getMetaTitle = () => {
    let data = title
    if (extraData && extraData.hasOwnProperty(i18n.language) && extraData[i18n.language]?.metas?.title)
      data = extraData[i18n.language].metas?.title

    return data
  }

  const getMetaDescription = () => {
    let data = removeHtmlTags(instructions)
    if (extraData && extraData.hasOwnProperty(i18n.language) && extraData[i18n.language]?.metas?.description)
      data = extraData[i18n.language].metas?.description

    return data
  }

  const getMetaImage = () => {
    let data = logo
    if (extraData && extraData.hasOwnProperty(i18n.language) && extraData[i18n.language]?.metas?.image)
      data = extraData[i18n.language].metas?.image

    return data
  }

  const metas = {
    title: getMetaTitle(),
    description: getMetaDescription(),
    url: `${import.meta.env.VITE_BASE_URL}/${pathnameExtract}`,
    image: getMetaImage(),
    author: '@olagg'
  }

  useMetaTags({
    title: metas.title,
    description: metas.description,
    charset: 'utf8',
    lang: 'es',
    metas: [
      { name: 'twitter:title', content: metas.title },
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:url', content: metas.url },
      { name: 'twitter:description', content: metas.description },
      { name: 'twitter:image', content: metas.image },
      { name: 'twitter:creator', content: metas.author },
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: metas.title },
      { property: 'og:description', content: metas.description },
      { property: 'og:site_name', content: 'Ola GG' },
      { property: 'og:url', content: metas.url },
      { property: 'og:image', content: metas.image },
      { property: 'og:image', content: metas.image },
      { property: 'fb:app_id', content: `${import.meta.env.VITE_FACEBOOK_CLIENT_ID}` },
      { p: 'og:image', c: metas.image },
    ],
  }, [metas])


  const setPartner = () => {
    localStorage.setItem('partner', pathnameExtract)

    let baseUrl = new URL(import.meta.env.VITE_BASE_URL)
    baseUrl.port = ''
    let domain = baseUrl.host
    const cookieOptions = {
      expires: 365,
      domain: domain
    }
    Cookies.set('x-partner', pathnameExtract, cookieOptions)

  }

  useEffect(() => {
    setPartner()
    let props = {
      user_agent: navigator.userAgent,
      owner: pathnameExtract
    }
    if (fcb != null) props.fbc = fcb
    if (fbp != null) props.fbp = fbp
    analytics()?.page("Quest Landing", props)

  }, [pathnameExtract])

  const navigate = useNavigate()

  const changeLang = (lang) => {
    const currentPath = window.location.pathname.split('/').slice(2).join('/');
    i18n.changeLanguage(lang);
    const searchParams = location.search;
    localStorage.setItem('i18n_lang', lang);
    navigate(`/${lang}/${currentPath}${searchParams}`, { replace: true });
  }

  useEffect(() => {
    if (!isPartner) {
      window.location.href = `${import.meta.env.VITE_BASE_URL}/404.html`
    }
  }, [isPartner])

  return (

    <Flex
      w={'full'}
      position={'relative'}
    >
      <Flex
        width='100vw'
        height={'100%'}
        position='relative'
        zIndex={1}
      >

        {
          isLoading ? (
            <Center
              w={'100%'}
              h={'1000px'}
              color={'white'}
            >
              <Flex
                w={'100%'}
                h={'1000px'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Spinner color={theme.fontColor} size="lg" />
              </Flex>
            </Center>
          ) : (
            <>
              <Flex w={'full'} justifyContent='center' p={'16px'} h='100%' minH={'100%'}>
                <Flex
                  flexDirection={'column'}
                  position="relative"
                  w={'full'}
                  alignItems={'center'}
                >
                  <Flex
                    w={'full'}
                    justifyContent={'center'}
                    marginBottom={'32px'}
                  >
                    <Image
                      src={logo}
                      maxHeight={{ base: '68px', xl: '120px' }}
                      objectFit={'contain'}
                    />
                  </Flex>
                  <Flex>
                    <Text
                      fontSize={{ base: '28px', md: '32px', lg: '32px', xl: '32px' }}
                      fontWeight={'700'}
                      color={theme.fontColor}
                      marginBottom={'32px'}
                      textAlign={'center'}
                    >
                      {i18n.language === 'es' ? title : (i18n.language === 'en' ? (extraData?.en?.title ?? title) : (extraData?.pt?.title ?? title))}
                    </Text>
                  </Flex>
                  <Flex>
                    <OlaggMarkdown color={theme.headerFontColor} textAlign='center'>
                      {i18n.language === 'es' ? instructions : (i18n.language === 'en' ? (extraData?.en?.instructions ?? instructions) : (extraData?.pt?.instructions ?? instructions))}
                    </OlaggMarkdown>
                  </Flex>
                  <Button
                    bg={mainColor}
                    w={{ base: '100%', md: '100%', lg: '45%', xl: '45%' }}
                    padding={'10px 24px'}
                    borderRadius={'6px'}
                    fontSize={'18px'}
                    fontWeight={'600'}
                    marginBottom={'68px'}
                    onClick={() => openOnboarding()}
                    color={theme.fontColor}
                    _hover={{ boxShadow: `0px 0px 12px 0px ${mainColor}` }}
                  >
                    {t('quests.partners_landing.cta_text')}
                  </Button>
                  <Text
                    fontSize={'14px'}
                    fontWeight={'400'}
                    color={theme.fontColor}
                  >
                    Powered by
                  </Text>
                  <Text>
                    <LogoText color={theme.fontColor} />
                  </Text>
                </Flex>
                <Flex
                  position={'absolute'}
                  top={'28px'}
                  left={'75%'}
                  zIndex='20'
                >
                  <DropdownMenu
                    buttonText={i18n.language.toLocaleUpperCase()}
                    theme={theme}
                    menuOptions={[
                      { text: 'Español', onClick: () => changeLang('es') },
                      { text: 'English', onClick: () => changeLang('en') },
                      { text: 'Português', onClick: () => changeLang('pt') },
                    ]}
                  />
                </Flex>
              </Flex>
            </>
          )
        }
      </Flex>
      <Flex
        w={'100vw'}
        h={'100vh'}
        bg={theme.background}
        backgroundImage={extraData?.bgImage ? `url(${extraData.bgImage})` : 'none'}
        backgroundSize="cover"
        backgroundPosition="center"
        position={'fixed'}
        zIndex='0'
      />
    </Flex>
  )
}

export default PartnersLanding