import { Button, Flex, Text, useMediaQuery, useToast } from '@chakra-ui/react'
import { QUEST_STATUS, QUEST_TYPES } from '@olagg/db-types'
import { useAuthStore, useOnboardingStore, useQuestsStore } from '@olagg/store'
import { useContext, useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

import Check from "@olagg/ui-kit/svg/Check"
import Close from "@olagg/ui-kit/svg/Close"
import ClaimedModal from '../../modals/ClaimedModal'
import QuestModal from '../QuestModal'
import SecretWordModal from '../../modals/SecretWordModal'
import TRIGGERS from '../../triggers'
import { XpStatic } from '@olagg/ui-kit/svg/XpStatic'
import { analytics } from '../../../../analytics'
import { useNavigate } from 'react-router-dom'
import usePartners from '../../hooks/usePartners'
import useQuest from '../../hooks/useQuest'
import { useTranslation } from 'react-i18next'
import { AdTrackingContext } from '../../../../contexts/adTrackingContext'

const QuestCard = ({ quest, reloadQuests }) => {
  const { fcb, fbp } = useContext(AdTrackingContext)
  const lang = localStorage.getItem('i18n_lang') || 'es'
  const [hover, setHover] = useState(false)
  const [visible, setVisible] = useState(false)
  const [verifying, setVerifying] = useState(false)
  const { loggedIn, getMe } = useAuthStore()
  const { openOnboarding } = useOnboardingStore()
  const navigate = useNavigate()
  const { completeQuest, claimQuest } = useQuest()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isOpenClaimedModal, setIsOpenClaimedModal] = useState(false)
  const [isSecretModalOpen, setIsSecretModalOpen] = useState(false)
  const { theme, mainColor } = usePartners()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const { pathname } = useLocation()
  const pathnameExtract = pathname.split(`/`)[1]

  const toast = useToast()

  const claimed = quest.status === QUEST_STATUS.CLAIMED
  const completed = quest.status === QUEST_STATUS.COMPLETED
  const disabled = quest.status === QUEST_STATUS.DISABLED
  const pending = quest.status === QUEST_STATUS.PENDING
  const exhausted = quest.status === QUEST_STATUS.EXHAUSTED

  const adminCompletion = quest.type === QUEST_TYPES.ADMIN_COMPLETION
  const automatic = quest.type === QUEST_TYPES.AUTOMATIC
  const manual = quest.type === QUEST_TYPES.MANUAL
  const oneClick = quest.type === QUEST_TYPES.ONE_CLICK
  const secretWord = quest.type === QUEST_TYPES.SECRET_WORD
  const description = lang === 'es' ? quest.description : quest.extraData?.[lang]?.description || quest.description
  const buttonText = lang === 'es' ? quest.buttonText : quest.extraData?.[lang]?.buttonText || quest.buttonText

  const parent = quest.parents?.[0]

  const dependsOnFilteredStatus = parent && quest.status !== QUEST_STATUS.PENDING || false

  const title = () => {
    const _title = lang === 'es' ? quest.title : quest.extraData?.[lang]?.title || quest.title
    if (quest.recursive) {
      const n = quest.iteration * quest.multiplier
      return _title?.replace('[N]', n.toString())
    }
    return _title
  }

  const onMouseEnterHandler = () => {
    if ((quest.description || dependsOnFilteredStatus) && !claimed) setHover(true)
  }

  const onMouseLeaveHandler = () => setHover(false)

  const onClickVisibleHandler = () => {
    if (!completed && !claimed) setVisible(!visible)
  }

  const goToUrl = () => {
    if (!loggedIn) return openOnboarding()
    if (quest.action.startsWith('https://')) window.open(quest.action, '_blank')
    else navigate(quest.action)
  }

  const handleCompleteQuest = async () => {
    if (!loggedIn) return openOnboarding()
    if (secretWord) return handleModalSecretWord()
    try {
      setVerifying(true)

      let props = {
        quest_id: quest.id,
        quest_name: quest.title,
        quest_xp: quest.score,
        owner: pathnameExtract,
        user_agent: navigator.userAgent,
      }
      if (fcb != null) props.fbc = fcb
      if (fbp != null) props.fbp = fbp

      if (oneClick) {
        analytics()
          ?.track('Quest Started', props)
      }

      await completeQuest(quest)

      analytics().track('Quest Verification Requested', props)

      setVerifying(false)
      reloadQuests()
    } catch (error) {
      setVerifying(false)
    }
  }

  const handleClaim = async () => {
    if (!loggedIn) return openOnboarding()
    setVerifying(true)
    try {
      await claimQuest(quest)
      setVerifying(false)
      getMe()
      setIsOpenClaimedModal(true)
    } catch (error) {
      setVerifying(false)
    }
  }

  const onSubmitSecretWord = () => {
    reloadQuests()
    getMe()
  }

  const handleModalSecretWord = () => {
    let props = {
      quest_id: quest.id,
      quest_name: quest.title,
      quest_xp: quest.score,
      owner: pathnameExtract,
      user_agent: navigator.userAgent,
    }
    if (fcb != null) props.fbc = fcb
    if (fbp != null) props.fbp = fbp
    analytics()
      ?.track('Quest Started', props)
    setIsSecretModalOpen(true)
  }

  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')
  const [isLargerThan1025] = useMediaQuery('(min-width: 1025px)')
  const { activeQuest, setActiveQuest } = useQuestsStore()

  const handleModalOpen = () => {
    setActiveQuest(quest)

    if (!Object.keys(TRIGGERS).includes(quest.trigger)) {
      if (secretWord) {
        handleModalSecretWord()
      } else {
        setIsOpenModal(true)
      }
    }
  }

  const customComponent = () => {
    if (Object.keys(TRIGGERS).includes(quest.trigger)) {
      if (activeQuest?.status === QUEST_STATUS.PENDING) {
        const CustomQuestModal = TRIGGERS[quest.trigger]
        return <><CustomQuestModal /></>
      }
    }
    return null
  }

  const checkQueryParams = () => {
    const questId = searchParams.get('checkQuest')
    if (questId == quest.id) {
      console.log("QUEST IDDD", questId);
      handleModalOpen()
    }
  }

  useEffect(() => {
    checkQueryParams()
  }, [])

  return (
    <Flex
      id={quest.id}
      padding={'24px 36px'}
      bg={theme.cardBg}
      borderRadius={'12px'}
      borderWidth={'2px'}
      borderColor={theme.stroke}
      marginBottom={'16px'}
      alignItems={'center'}
      justifyContent={'space-between'}
      flexDir={{ base: 'column', md: 'column', lg: 'column', xl: 'row' }}
    >
      <Text
        fontSize={{ base: '20px', md: '24px' }}
        fontWeight={'600'}
        color={theme.fontColor}
        textAlign={{ base: 'center', md: 'center', lg: 'center', xl: 'start' }}
      >
        {title()}
      </Text>
      <Flex
        alignItems={'center'}
        w={{ base: 'full', md: 'full', lg: 'full', xl: 'unset' }}
        flexDir={{ base: 'column', md: 'column', lg: 'column', xl: 'row' }}
        marginTop={{ base: '16px', md: '0px' }}
        marginBottom={{ base: '8px', md: '0px' }}
      >
        <Flex alignItems={'center'} marginBottom={!isLargerThan768 ? '12px' : 'inherit'}>
          <Text
            fontSize={{ base: '24px', md: '36px' }}
            fontWeight={'500'}
            color={theme.fontColor}
            marginRight={'8px'}
          >
            {quest.score}
          </Text>
          {
            isLargerThan768 ? (
              <XpStatic width={'40px'} height={'20px'} color={theme.fontColor} />
            ) : (
              <XpStatic width={'37px'} height={'17px'} color={theme.fontColor} />
            )
          }
        </Flex>
        {
          (claimed || exhausted) ? (
            <Flex
              marginLeft={{ base: '0px', md: '0px', lg: '0px', xl: '24px' }}
              width={{ base: 'full', xl: '200px' }}
              bg={theme.cardBg}
              padding={'10px 24px'}
              borderRadius={'6px'}
              borderWidth={'1px'}
              borderColor={theme.fontColor}
              alignItems={'center'}
              justifyContent={'center'}
            >
              {exhausted ? <Close color='red' /> : <Check color={theme.fontColor} />}
              <Text
                marginLeft={'8px'}
                color={theme.fontColor}
                fontSize={{ base: '16px', md: '18px' }}
                fontWeight={'600'}
              >
                {exhausted ? t("quests.exhausted_text") : t("quests.completed_text")}
              </Text>
            </Flex>
          ) : (
            <Button
              marginLeft={{ base: '0px', md: '0px', lg: '0px', xl: '24px' }}
              width={{ base: 'full', xl: '200px' }}
              bg={completed ? mainColor : theme.questsBtn}
              borderRadius={'6px'}
              color={theme.questBtnFontColor}
              _hover={completed ? { boxShadow: `0px 0px 12px 0px ${mainColor}` } : { bg: mainColor }}
              fontSize={{ base: '16px', md: '18px' }}
              fontWeight={'600'}
              onClick={completed ? handleClaim : handleModalOpen}
            >
              {completed ? t("quests.claim_text") : buttonText}
            </Button>
          )
        }
      </Flex>
      <SecretWordModal handleClaim={handleClaim} quest={quest} isOpen={isSecretModalOpen} setIsOpen={setIsSecretModalOpen} onSubmit={onSubmitSecretWord} />
      <QuestModal reloadQuests={reloadQuests} quest={quest} isOpen={isOpenModal} setIsOpen={setIsOpenModal} />
      {customComponent()}
      <ClaimedModal reload={reloadQuests} quest={quest} isOpen={isOpenClaimedModal} handleToggleModal={setIsOpenClaimedModal} />
    </Flex>
  )
}

export default QuestCard