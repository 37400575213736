import React from 'react';
import twitterWidgetJs from './twiter-widget-url';
import script from 'scriptjs';
import { Box, Spinner, useMediaQuery } from '@chakra-ui/react';

declare global {
    interface Window {
        twttr: any;
    }
}

interface JSONObject {
    [k: string]: any;
}

export interface TwitterTweetEmbedProps {
    tweetId: string;
    options?: JSONObject;
    placeholder?: string | React.ReactNode;
    onLoad?: (element: any) => void;
}

const methodName = 'createTweet';

const TwitterTweetEmbed = (props: TwitterTweetEmbedProps): any => {
    const ref = React.useRef<HTMLDivElement | null>(null);
    const [loading, setLoading] = React.useState(true);
    const [isLargerThan769] = useMediaQuery('(min-width: 769px)')

    React.useEffect(() => {
        let isComponentMounted = true;

        script(twitterWidgetJs, 'twitter-embed', () => {
            if (!window.twttr) {
                console.error('Failure to load window.twttr, aborting load');
                return;
            }
            if (isComponentMounted) {
                if (!window.twttr.widgets[methodName]) {
                    console.error(
                        `Method ${methodName} is not present anymore in twttr.widget api`
                    );
                    return;
                }

                window.twttr.widgets[methodName](
                    props.tweetId,
                    ref?.current,
                    props.options
                ).then((element: any) => {
                    setLoading(false);
                    if (props.onLoad) {
                        props.onLoad(element);
                    }
                });
            }
        });

        return () => {
            isComponentMounted = false;
        };
    }, []);

    return (
        <Box w={'100%'} textAlign={'center'}>
            {loading && <Spinner m={'20px'} />}
            <Box ref={ref} w={loading ? '0px' : isLargerThan769 ? '500px' : '300px'} mx={'auto'} />
        </Box>
    );
};

export default TwitterTweetEmbed;