import { Center, Flex, Spinner, Text } from '@chakra-ui/react'
import { QUEST_STATUS, QUEST_TYPES } from '@olagg/db-types'
import { useEffect, useRef, useState } from 'react'

import { Quest } from '@olagg/db-types'
import QuestCard from './QuestCard'
import usePartners from '../../hooks/usePartners'
import useQuest from '../../hooks/useQuest'
import { useTranslation } from 'react-i18next'
import QuestCompletedModal from '../../modals/QuestCompletedModal'
import { useQuestsStore } from '@olagg/store'

const Quests = () => {
  const [quests, setQuests] = useState<Quest[]>([])
  const [completedQuests, setCompletedQuests] = useState<Quest[]>([])
  const { all, groupedByOwner, byOwnerId } = useQuest()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { theme, mainColor, ownerId, essentialQuests, isOlaGG } = usePartners()
  const { t } = useTranslation()
  const { activeQuest } = useQuestsStore();

  const reloadQuests = () => {
    setIsLoading(true);

    // Primera llamada con el primer ownerId
    all({ ownerId: "olagg", page: 1, limit: 100 })
      .then(({ quests: quests1 }) => {
        // Filtrar quests1 para incluir solo las que están en essentialQuests
        const filteredQuests1 = quests1.filter(quest1 =>
          essentialQuests?.some(essentialQuest => essentialQuest.value === quest1.id)
        );

        byOwnerId(ownerId).then(({ quests: quests2 }) => {
          // Unir los resultados de ambas llamadas
          const allQuests = [...filteredQuests1, ...quests2];

          if (isOlaGG) {
            const completed = quests2.filter(quest => quest.status === QUEST_STATUS.CLAIMED);
            const incomplete = quests2.filter(quest => quest.status !== QUEST_STATUS.CLAIMED);
            setQuests(incomplete);
            setCompletedQuests(completed);
          } else {
            const completed = allQuests.filter(quest => quest.status === QUEST_STATUS.CLAIMED);
            const incomplete = allQuests.filter(quest => quest.status !== QUEST_STATUS.CLAIMED);
            setQuests(incomplete);
            setCompletedQuests(completed);
          }

        }).finally(() => setIsLoading(false));
      });
  };



  useEffect(() => {
    reloadQuests()
  }, [])

  if (isLoading) return (
    <Center w='100%' minH='250px' color='white'>
      <Flex w='100%' minH='250px' justify='center' align='center' color='white'>
        <Spinner color={theme.fontColor} size="lg" />
      </Flex>
    </Center>
  )

  return (
    <Flex
      w={'full'}
      justifyContent={'center'}
      paddingRight={{ base: '0px', md: '0px', lg: '20%', xl: '0px' }}
      paddingLeft={{ base: '0px', md: '0px', lg: '20%', xl: '0px' }}
    >
      <Flex
        w={'1100px'}
        flexDir={'column'}
      >
        {
          quests.length > 0 && (
            <>
              <Text
                fontSize={{ base: '24px', md: '24px', lg: '24px', xl: '36px' }}
                color={theme.fontColor}
                fontWeight={'600'}
                marginBottom={'16px'}
              >
                {t('quests.title')}
              </Text>
              {quests.map((quest, index) => (
                <QuestCard reloadQuests={reloadQuests} key={index} quest={quest} />
              ))}
              {activeQuest?.status === QUEST_STATUS.COMPLETED && <QuestCompletedModal reloadQuests={reloadQuests} />}
            </>
          )
        }
        {
          completedQuests.length > 0 && (
            <>
              <Text
                fontSize={{ base: '24px', md: '24px', lg: '24px', xl: '36px' }}
                color={theme.fontColor}
                fontWeight={'600'}
                marginBottom={'16px'}
                marginTop={'52px'}
              >
                {t('quests.title_2')}
              </Text>
              {completedQuests.map((quest, index) => (
                <QuestCard reloadQuests={reloadQuests} key={index} quest={quest} />
              ))}
            </>
          )
        }
      </Flex>
    </Flex>
  )
}

export default Quests